import { NotificationAdd } from "@mui/icons-material";
import React, { useEffect, useState } from "react";

import { useLocation } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import {
  getFaqId,
  PostFaqs,
  updateFaq,
  postNotification,
} from "../../redux/api";

export const AddFaqForm = () => {
  const [spinn, setspinn] = useState(false);
  const history = useHistory();
  const params = useParams();
  const [filterfaqData, setfilterfaqData] = useState({});
  const [faqData, setfaqData] = useState({
    englishInfo: {
      question: "",
      answer: "",
    },
    hindiInfo: {
      question: "",
      answer: "",
    },
    tamilInfo: {
      question: "",
      answer: "",
    },
    teluguInfo: {
      question: "",
      answer: "",
    },
    marathiInfo: {
      question: "",
      answer: "",
    },
    kannadInfo: {
      question: "",
      answer: "",
    },
  });

  useEffect(() => {
    if (params.id !== undefined) {
      Query();
    }
  }, [params.id]);

  // api call

  const Query = async () => {
    try {
      const data = await getFaqId({
        id: params.id,
      });
      setfilterfaqData(data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handlesubmit = async (e) => {
    e.preventDefault();
    setspinn(true);
    if (params.id !== "add") {
      const data = {
        id: params.id,
        updatedFaq: {
          englishInfo: {
            question:
              faqData.englishInfo.question ||
              filterfaqData.englishInfo.question,
            answer:
              faqData.englishInfo.answer || filterfaqData.englishInfo.answer,
          },
          hindiInfo: {
            question:
              faqData.hindiInfo.question || filterfaqData.hindiInfo.question,
            answer: faqData.hindiInfo.answer || filterfaqData.hindiInfo.answer,
          },
          tamilInfo: {
            question:
              faqData.tamilInfo.question || filterfaqData.tamilInfo.question,
            answer: faqData.tamilInfo.answer || filterfaqData.tamilInfo.answer,
          },
          teluguInfo: {
            question:
              faqData.teluguInfo.question || filterfaqData.teluguInfo.question,
            answer:
              faqData.teluguInfo.answer || filterfaqData.teluguInfo.answer,
          },
          marathiInfo: {
            question:
              faqData.marathiInfo.question ||
              filterfaqData.marathiInfo.question,
            answer:
              faqData.marathiInfo.answer || filterfaqData.marathiInfo.answer,
          },
          kannadInfo: {
            question:
              faqData.kannadInfo.question || filterfaqData.kannadInfo.question,
            answer:
              faqData.kannadInfo.answer || filterfaqData.kannadInfo.answer,
          },
        },
      };
      try {
        const res = await updateFaq(data);
        setspinn(false);
        history.push("/faq");
      } catch (err) {
        console.log(err);
        setspinn(false);
        alert(err.message);
      }
    } else {
      try {
        const data = await PostFaqs(faqData);
        setspinn(false);
        history.push("/faq");
      } catch (error) {
        console.log(error);
        setspinn(false);
        alert("Please Fill All Required Information");
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, id } = e.target;
    setfaqData({
      ...faqData,
      [name]: {
        question: id === "question" ? value : faqData[name].question,
        answer: id === "answer" ? value : faqData[name].answer,
      },
    });
  };
  console.log(faqData);

  return (
    <>
      <form>
        <div className="addproperty-container mb-5">
          <div className="addproperty-personalDetails">
            {/* 1st row */}
            <div
              className="addproperty-alignRow"
              style={{ flexDirection: "column" }}
            >
              <div className="w-100 form-group mb-2">
                <label className="addproperty-inputLabel ">
                  FAQs (English)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>
                    *
                  </span>{" "}
                </label>
                <input
                  type="text"
                  defaultValue={filterfaqData?.englishInfo?.question || ""}
                  name="englishInfo"
                  id="question"
                  placeholder="Enter FAQs Question (English)"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
                <textarea
                  style={{ borderTop: "none" }}
                  defaultValue={filterfaqData?.englishInfo?.answer || ""}
                  name="englishInfo"
                  id="answer"
                  placeholder="Enter FAQs Answer (English)"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <hr />
              <div className="w-100 form-group mb-2">
                <label className="addproperty-inputLabel ">
                  FAQs (Hindi)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>
                    *
                  </span>{" "}
                </label>
                <input
                  type="text"
                  name="hindiInfo"
                  id="question"
                  defaultValue={filterfaqData?.hindiInfo?.question || ""}
                  placeholder="Enter FAQs Question (Hindi)"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
                <textarea
                  defaultValue={filterfaqData?.hindiInfo?.answer || ""}
                  name="hindiInfo"
                  id="answer"
                  placeholder="Enter FAQs Answer (Hindi)"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <hr />
              <div className="w-100 form-group mb-2">
                <label className="addproperty-inputLabel ">
                  FAQs (Marathi)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
                </label>
                <input
                  type="text"
                  defaultValue={filterfaqData?.marathiInfo?.question || ""}
                  name="marathiInfo"
                  id="question"
                  placeholder="Enter FAQs Question (Marathi)"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
                <textarea
                  defaultValue={filterfaqData?.marathiInfo?.answer || ""}
                  name="marathiInfo"
                  id="answer"
                  placeholder="Enter FAQs answer (English)"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 form-group mb-2">
                <label className="addproperty-inputLabel ">
                  FAQs (Telugu)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
                </label>
                <input
                  defaultValue={filterfaqData?.teluguInfo?.question || ""}
                  type="text"
                  name="teluguInfo"
                  id="question"
                  placeholder="Enter FAQs Question (Telugu)"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
                <textarea
                  defaultValue={filterfaqData?.teluguInfo?.answer || ""}
                  name="teluguInfo"
                  id="answer"
                  placeholder="Enter FAQs Answer (Telugu)"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <hr />
              <div className="w-100 form-group">
                <label className="addproperty-inputLabel ">
                  FAQs (Tamil)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
                </label>
                <input
                  type="text"
                  defaultValue={filterfaqData?.tamilInfo?.question || ""}
                  name="tamilInfo"
                  id="question"
                  placeholder="Enter FAQs (Tamil)"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
                <textarea
                  defaultValue={filterfaqData?.tamilInfo?.answer || ""}
                  name="tamilInfo"
                  id="answer"
                  placeholder="Enter FAQs Answer (Tamil)"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <hr />
              <div className="w-100 form-group">
                <label className="addproperty-inputLabel ">
                  FAQs (Kannad)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
                </label>
                <input
                  type="text"
                  defaultValue={filterfaqData?.kannadInfo?.question || ""}
                  name="kannadInfo"
                  id="question"
                  placeholder="Enter FAQs Question (Kannad)"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
                <textarea
                  defaultValue={filterfaqData?.kannadInfo?.answer || ""}
                  name="kannadInfo"
                  id="answer"
                  placeholder="Enter FAQs Answer (Kannad)"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="addproperty-submitDetailDiv">
            <button
              className="addproperty-submitDetailBtn"
              onClick={handlesubmit}
            >
              {params.id ? "Update" : "Submit"}
              {spinn ? (
                <div
                  class="spinner-border spinner-border-sm text-white mx-2"
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                ""
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
