import React, { useState } from "react";
import { Button } from "react-bootstrap";
import AboutEditor from "./AboutEditor";
import PrivacyEditor from "./PrivacyEditor";
import TermsEditor from "./TermsEditor";
import TransTable from "../FeaturedVideos/transtable/TransTable";

const Forms = () => {
  const [select, setSelect] = useState("add");

  const tableHeaders = ["S. NO.", "Content", "Enable/Disable"]
  return (
    <div>
      <div className="m-4">
        <Button
          className="ms-4 me-4"
          onClick={() => setSelect("add")}
          style={{ opacity: select !== "add" ? "0.7" : "1" }}
        >
          Terms & Condition
        </Button>
        <Button
          className="ms-4 me-4"
          onClick={() => setSelect("about")}
          style={{ opacity: select !== "about" ? "0.7" : "1" }}
        >
          About Us
        </Button>
        <Button
          className="ms-4 me-4"
          onClick={() => setSelect("privacy")}
          style={{ opacity: select !== "privacy" ? "0.7" : "1" }}
        >
          Privacy
        </Button>
      </div>

      {/* {select === "add" && (
        <TransTable
          id={1}
          tableHeaders={tableHeaders}
          VideoData={"dfd"}
        />
      )} */}

      <div>
        {select === "add" && (
          <div className="my-5">
            <TermsEditor />
          </div>
        )}
        {select === "privacy" && (
          <div className="my-5">
            <PrivacyEditor />
          </div>
        )}
        {select === "about" && (
          <div className="my-5">
            <AboutEditor />
          </div>
        )}
      </div>
    </div>
  );
};

export default Forms;
