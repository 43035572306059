import React, { useState } from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  clearWalletMoney,
  fetchAllTranscation,
  fetchDriverDetails,
  fetchDrivers,
  fetchWalletMoney,
  updateDriverStatus,
} from "../../redux/api";
import "../../styles/newstyles/property.css";
import CircularLoader from "../../ui/CircularLoader/CircularLoader";
import ModalView from "../../ui/modalview/ModalView";
import LoadingPage from "../utils/LoadingPage";
import AFtable from "./AllFeatured/AFtable";
import TransTable from "./transtable/TransTable";
import Pagination from "@mui/material/Pagination";

const DriverDetails = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [reason, setReason] = useState("");
  const location = useLocation();
  const path = location.pathname;
  const pageLimit = 10;
  const handleClose = () => setOpen(false);
  const [loadingPage, setIsLoadingPage] = useState(false);
  const [update, setUpdate] = useState(false);
  const [loading2, setIsLoading2] = useState(false);
  const { id } = useParams();
  console.log(id);
  const tableHeaders = ["Index", "Name", "Phone", "Email", "Gender", "Status"];
  const tableHeaders1 = ["Index", "Action", "Discription", "Date", "Amount", "Payment Method"];

  const [allpropertyData, setallpropertyData] = useState([]);
  const [walletData, setWalletData] = useState([]);
  const [walletData1, setWalletData1] = useState([]);
  const [walletData2, setWalletData2] = useState([]);
  const [walletData3, setWalletData3] = useState([]);
  const [walletTotal, setWalletTotal] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [type, setType] = useState("");


  const handleChangeFitler = (e) => {
    setType(e.target.value)
  }

  const approveDriverHandler = async () => {
    setLoading(true);
    try {
      const res = await updateDriverStatus(id, "approve", "");
      if (res?.data?.success) {
        alert("Driver Approve Success");
        history.push("/drivers");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const rejectDriverHandler = async (e) => {
    e.preventDefault();
    setIsLoading2(true);
    try {
      const res = await updateDriverStatus(id, "reject", reason);
      if (res?.data?.success) {
        alert("Driver Rejected Success");
        history.push("/drivers");
      }
      setIsLoading2(false);
    } catch (error) {
      setIsLoading2(false);
    }
  };

  const fetchDriversData = async () => {
    setIsLoadingPage(true);
    try {
      const res = await fetchDriverDetails(id);
      console.log(res);
      setallpropertyData(res?.data?.driver);
      // setVideoCount(res?.data?.pageLimit);
      setIsLoadingPage(false);
    } catch (error) {
      console.log(error);
      setIsLoadingPage(false);
    }
  };
  const fetchTranscations = async () => {
    // setIsLoadingPage(true);
    try {
      const res = await fetchAllTranscation(id, page, type);
      console.log(res);

      setWalletData(res?.data?.wallet);
      setWalletData1(res?.data?.wallet);
      setPage(res?.data?.page)
      setPages(res?.data?.pages)
      console.log(res?.data?.wallet)
      setWalletData2(
        res?.data?.wallet?.filter((item) => item?.paymentMode === "online")
      );
      setWalletData3(
        res?.data?.wallet?.filter((item) => item?.paymentMode === "cash")
      );
      // setVideoCount(res?.data?.pageLimit);
      // setIsLoadingPage(false);
    } catch (error) {
      console.log(error);
      // setIsLoadingPage(false);
    }
  };
  const fetchMoney = async () => {
    // setIsLoadingPage(true);
    try {
      const res = await fetchWalletMoney(id);
      console.log(res);


      setWalletTotal(res?.data?.wallet?.total);
      // setIsLoadingPage(false);
    } catch (error) {
      console.log(error);
      // setIsLoadingPage(false);
    }
  };

  const clearHandle = async (e) => {
    e.preventDefault();
    try {
      const res = await clearWalletMoney(id);
      console.log(res);
      if (res?.data?.success === true) {
        setUpdate(true);
        alert("Money has been withdrawn");
      }

      // setIsLoadingPage(false);
    } catch (error) {
      console.log(error);
      // setIsLoadingPage(false);
    }
  };


          const pageChangeHandler = (e, value) => {
            setPage(value);
          };

  useEffect(() => {
    fetchTranscations();
  }, [page, type]);
  useEffect(() => {
    fetchDriversData();
    fetchTranscations();
    fetchMoney();
  }, []);
  
  useEffect(() => {
    if (update) {
      fetchMoney();
      setUpdate(false);
    }
  }, [update]);
  console.log(allpropertyData);
  return (
    <div className="property-container">
      <div>
        {allpropertyData?.status === "pending" ||
        allpropertyData?.status === null ? (
          <>
            <Button onClick={approveDriverHandler} variant="contained">
              {loading ? <CircularLoader color="white" /> : "Approve"}
            </Button>
            <Button onClick={handleOpen} variant="outlined">
              {loading2 ? <CircularLoader color="blue" /> : "Reject"}
            </Button>
          </>
        ) : allpropertyData?.status === "rejected" ? (
          <h6 style={{ color: "red" }}>Rejected</h6>
        ) : allpropertyData?.status === "verified" ? (
          <h6 style={{ color: "green" }}>Approved</h6>
        ) : null}
      </div>
      <div className="property-tableSection">
        {loadingPage ? (
          <LoadingPage />
        ) : (
          <AFtable
            path={path}
            id={id}
            tableHeaders={tableHeaders}
            VideoData={allpropertyData}
          />
        )}
      </div>

      <div>
        {!loadingPage && (
          <div className="d-flex justify-content-between">
            <h3>Wallet Total -{Number(walletTotal).toFixed(2)}</h3>
            <div className="d-flex align-items-center">
              <select
                onChange={(e) => handleChangeFitler(e)}
                style={{ height: "35px", borderRadius: "6px", outline: "none" }}
                className="me-3"
                name="filter"
                id="filter"
              >
                <option value="">All</option>
                <option value="online">Online</option>
                <option value="cash">Cash</option>
              </select>
              <Button onClick={(e) => clearHandle(e)}>WithDraw</Button>
            </div>
          </div>
        )}
      </div>

      <div className="property-tableSection">
        {loadingPage ? (
          <LoadingPage />
        ) : (
          <TransTable
            id={id}
            tableHeaders={tableHeaders1}
            VideoData={walletData}
          />
        )}
      </div>
      <div className="d-flex justify-content-end">
        <Pagination
          count={pages}
          page={page}
          onChange={pageChangeHandler}
        />
      </div>

      <ModalView open={open} handleClose={handleClose}>
        <form
          onSubmit={rejectDriverHandler}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h4 style={{ textAlign: "center" }}>Reason</h4>
          <textarea
            required
            onChange={(e) => setReason(e.target.value)}
            name="reason"
            id="reason"
            style={{ width: "100%", outline: "none", marginBottom: "30px" }}
            rows="10"
          ></textarea>
          <div>
            <Button
              // className={styles.btnPrimary}
              type="submit"
              variant="contained"
            >
              {loading2 ? <CircularLoader color="blue" /> : "Reject"}
            </Button>
            <Button
              // className={styles.btnSecondary}
              onClick={handleClose}
              variant="outlined"
            >
              Cancel
            </Button>
          </div>
        </form>
      </ModalView>
    </div>
  );
};

export default DriverDetails;
