import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import LoadingPage from "../utils/LoadingPage";
import addIcon from "../../images/addIcon.svg";
import searchIcon from "../../images/searchIcon.svg";
import "../../styles/newstyles/unitdetails.css";
import Utable from "./AllUser/Utable";
import { fetchsearchUser, GetQuery, GetUsers } from "../../redux/api";
import exportFromJSON from "export-from-json";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
// import { Pagination } from "react-bootstrap";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
const User = () => {
  const history = useHistory();
  const { id } = useParams();
  const [UserData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchInput, setsearchInput] = useState("");
  const [page, setPage] = useState(0);
  const [filterData, setfilterData] = useState([]);
  const [totalPage, setTotalPage] = useState(1);


  const pageLimit = 10;
  useEffect(() => {
    getAllUser();
  }, []);
  useEffect(() => {
    getAllUser();
  }, [page]);

  const getAllUser = async () => {
    try {
      const { data } = await GetUsers(page);
      setUserData(data?.user);
      console.log(data?.user);
      setTotalPage(data?.pages);
      setPage(data?.page)
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const searchItems = async (searchValue) => {
    setsearchInput(searchValue);
    if (searchValue !== "") {
      const res = await fetchsearchUser(searchValue);
      console.log(res);
     setTotalPage(res?.data?.pages);
      setPage(res?.data?.page)
      setfilterData(res?.data?.result);
    } else {
      setfilterData(UserData);
    }
  };

  //TODO: Download user data

  // const downloadUsers = () => {
  //   const fileName = "users";
  //   const exportType = exportFromJSON.types.csv;
  //   const userWithouPassword = UserData.map((user) => {
  //     return {
  //       _id: user._id,
  //       name: user.name,
  //       email: user.email,
  //       phone: user.phone,
  //       username: user.username,
  //       organisation: user.organisation,
  //       designation: user.designation,
  //       age: user.age,
  //       address: user.address,
  //       profile: user.profile,
  //       status: user.status,
  //       createdAt: user.createdAt,
  //       updatedAt: user.updatedAt,
  //     };
  //   });
  //   const data = userWithouPassword;
  //   exportFromJSON({ data, fileName, exportType });
  // };

        const pageChangeHandler = (e, value) => {
        setPage(value);
      }

  return (
    <div className="unitdetails-container">
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <div className="unitdetails-firstSection">
            <div className="unitdetails-searchDiv">
              <img src={searchIcon} alt="search" className="searchIcon" />
              <input
                type="text"
                placeholder="Search by Phone, Email and Name"
                className="unitdetails-searchInput"
                id="searchInput"
                value={searchInput}
                onChange={(e) => searchItems(e.target.value)}
              />
            </div>
            {/* <button
              className="btn-primary-admin"
              onClick={() => downloadUsers()}
            >
              <CloudDownloadIcon /> Export Users
            </button> */}
          </div>
          <div className="unitdetails-tableSection">
            {searchInput.length ? (
              <Utable page={page} UserData={filterData} />
            ) : (
              <Utable page={page} UserData={UserData} />
            )}
          </div>
          <div className="d-flex justify-content-end">
<Pagination count={totalPage} page={page} onChange={pageChangeHandler}/>
          </div>
        </>
      )}
    </div>
  );
};
export default User;
