import React, { useEffect, useState } from "react";
import { allImage, fetchAllRequest } from "../../redux/api";
import RequestTable from "./RequestTable";
import { useLocation } from "react-router-dom";

const Request = () => {
  const [data, setData] = useState();
  const location = useLocation();
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const path = location.pathname;
  const tableHeaders = ["ID", "From", "To", "Reason","Driver Name", "Phone No.", "Car No.", "Status"];

  const fetchImageData = async () => {
    setLoading(true);
    try {
      const res = await fetchAllRequest();
      console.log(res?.data);
      setData(res?.data);
      // setVideoCount(res?.data?.pageLimit);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      fetchImageData();
    }, 15000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    fetchImageData();
  }, []);

  return (
    <div className="property-container">
      <div className="property-tableSection">
        <RequestTable
          tableHeaders={tableHeaders}
          VideoData={data}
          setUpdate={setUpdate}
          path={path}
        />
      </div>
    </div>
  );
};

export default Request;
