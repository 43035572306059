import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import useStyles from "../styles/NavSidebar";
import ArticleIcon from "@mui/icons-material/Article";
import "./NavSidebar.css";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import logo from "../images/logo.png";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import CategoryIcon from "@mui/icons-material/Category";
import PersonIcon from "@mui/icons-material/Person";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";

const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "#fff",
  color: "white",
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiTypography-body1": {
    fontFamily: "Montserrat !important",
  },
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    overflowX: "hidden",
    width: drawerWidth,
    height: "100vh",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    fontFamily: "Montserrat",
    backgroundColor: "#101010",
    color: "white",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

const NavSidebar = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const history = useHistory();

  const handleLogout = () => {
    localStorage.clear();
    window.location.replace("/");
  };

  let k = localStorage.getItem("admin_bearer");
  useEffect(() => {
    if (!k) {
      history.push("/");
    }
  }, []);

  const handleListClick = (url) => {
    history.push(url);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {/**Dashboard */}
            </Typography>
            <div className={classes.logoutBtnDiv}>
              <button
                className={classes.logoutBtn}
                onClick={() => handleLogout()}
              >
                Log out
              </button>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: [1],
              border: "2px solid white",
              backgroundColor: "white",
            }}
          >
            <div
              style={{ backgroundColor: "white", border: "2px solid white" }}
            >
              <img src={logo} height="50px"></img>
            </div>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon className={classes.closeDrawer} />
            </IconButton>
          </Toolbar>
          <Divider />

          <List className={classes.listDiv}>
            <ListItem
              button
              className={
                props.location.pathname.includes("/dashboard")
                  ? classes.selectedList
                  : ""
              }
              onClick={() => handleListClick("/dashboard")}
            >
              <ListItemIcon>
                {props.location.pathname.includes("/dashboard") ? (
                  <DashboardIcon
                    style={{ color: "white", fontSize: "1.8rem" }}
                  />
                ) : (
                  <DashboardIcon
                    style={{ color: "white", fontSize: "1.8rem" }}
                  />
                )}
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem
              button
              className={
                props.location.pathname.includes("/users")
                  ? classes.selectedList
                  : ""
              }
              onClick={() => handleListClick("/users")}
            >
              <ListItemIcon>
                {props.location.pathname.includes("/users") ? (
                  <PersonIcon style={{ color: "white", fontSize: "1.8rem" }} />
                ) : (
                  <PersonIcon style={{ color: "white", fontSize: "1.8rem" }} />
                )}
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>

            <ListItem
              button
              className={
                props.location.pathname.includes("/drivers")
                  ? classes.selectedList
                  : ""
              }
              onClick={() => handleListClick("/drivers")}
            >
              <ListItemIcon>
                {props.location.pathname.includes("/drivers") ? (
                  <PersonIcon style={{ color: "white", fontSize: "1.8rem" }} />
                ) : (
                  <PersonIcon style={{ color: "white", fontSize: "1.8rem" }} />
                )}
              </ListItemIcon>
              <ListItemText primary="Drivers" />
            </ListItem>

            <ListItem
              button
              className={
                props.location.pathname.includes("/rides")
                  ? classes.selectedList
                  : ""
              }
              onClick={() => handleListClick("/rides")}
            >
              <ListItemIcon>
                {props.location.pathname.includes("/rides") ? (
                  <OndemandVideoIcon
                    style={{ color: "white", fontSize: "1.8rem" }}
                  />
                ) : (
                  <OndemandVideoIcon
                    style={{ color: "white", fontSize: "1.8rem" }}
                  />
                )}
              </ListItemIcon>
              <ListItemText primary="Rides" />
            </ListItem>
            <ListItem
              button
              className={
                props.location.pathname.includes("/forms")
                  ? classes.selectedList
                  : ""
              }
              onClick={() => handleListClick("/forms")}
            >
              <ListItemIcon>
                {props.location.pathname.includes("/forms") ? (
                  <OndemandVideoIcon
                    style={{ color: "white", fontSize: "1.8rem" }}
                  />
                ) : (
                  <OndemandVideoIcon
                    style={{ color: "white", fontSize: "1.8rem" }}
                  />
                )}
              </ListItemIcon>
              <ListItemText primary="forms" />
            </ListItem>
            <ListItem
              button
              className={
                props.location.pathname.includes("/offers")
                  ? classes.selectedList
                  : ""
              }
              onClick={() => handleListClick("/offers")}
            >
              <ListItemIcon>
                {props.location.pathname.includes("/offers") ? (
                  <OndemandVideoIcon
                    style={{ color: "white", fontSize: "1.8rem" }}
                  />
                ) : (
                  <OndemandVideoIcon
                    style={{ color: "white", fontSize: "1.8rem" }}
                  />
                )}
              </ListItemIcon>
              <ListItemText primary="offers" />
            </ListItem>
            <ListItem
              button
              className={
                props.location.pathname.includes("/uichanging")
                  ? classes.selectedList
                  : ""
              }
              onClick={() => handleListClick("/uichanging")}
            >
              <ListItemIcon>
                {props.location.pathname.includes("/uichanging") ? (
                  <OndemandVideoIcon
                    style={{ color: "white", fontSize: "1.8rem" }}
                  />
                ) : (
                  <OndemandVideoIcon
                    style={{ color: "white", fontSize: "1.8rem" }}
                  />
                )}
              </ListItemIcon>
              <ListItemText primary="Ui Changing" />
            </ListItem>
            <ListItem
              button
              className={
                props.location.pathname.includes("/requests")
                  ? classes.selectedList
                  : ""
              }
              onClick={() => handleListClick("/requests")}
            >
              <ListItemIcon>
                {props.location.pathname.includes("/requests") ? (
                  <OndemandVideoIcon
                    style={{ color: "white", fontSize: "1.8rem" }}
                  />
                ) : (
                  <OndemandVideoIcon
                    style={{ color: "white", fontSize: "1.8rem" }}
                  />
                )}
              </ListItemIcon>
              <ListItemText primary="Requests" />
            </ListItem>
            <ListItem
              button
              className={
                props.location.pathname.includes("/findcar")
                  ? classes.selectedList
                  : ""
              }
              onClick={() => handleListClick("/findcar")}
            >
              <ListItemIcon>
                {props.location.pathname.includes("/findcar") ? (
                  <OndemandVideoIcon
                    style={{ color: "white", fontSize: "1.8rem" }}
                  />
                ) : (
                  <OndemandVideoIcon
                    style={{ color: "white", fontSize: "1.8rem" }}
                  />
                )}
              </ListItemIcon>
              <ListItemText primary="Find Car" />
            </ListItem>

            {/* 
            <ListItem
              button
              className={
                props.location.pathname.includes("/blog")
                  ? classes.selectedList
                  : ""
              }
              onClick={() => handleListClick("/blog")}
            >
              <ListItemIcon>
                {props.location.pathname.includes("/blog") ? (
                  <ArticleIcon style={{ color: "white", fontSize: "1.8rem" }} />
                ) : (
                  <ArticleIcon style={{ color: "white", fontSize: "1.8rem" }} />
                )}
              </ListItemIcon>
              <ListItemText primary="Blogs" />
            </ListItem> */}

            {/* <ListItem
              button
              className={
                props.location.pathname.includes("/query")
                  ? classes.selectedList
                  : ""
              }
              onClick={() => handleListClick("/query")}
            >
              <ListItemIcon>
                {props.location.pathname.includes("/query") ? (
                  <ContactSupportIcon
                    style={{ color: "white", fontSize: "1.8rem" }}
                  />
                ) : (
                  <ContactSupportIcon
                    style={{ color: "white", fontSize: "1.8rem" }}
                  />
                )}
              </ListItemIcon>
              <ListItemText primary="Query Form" />
            </ListItem> */}

            {/* <ListItem
              button
              className={
                props.location.pathname.includes("/notification")
                  ? classes.selectedList
                  : ""
              }
              onClick={() => handleListClick("/notifications")}
            >
              <ListItemIcon>
                {props.location.pathname.includes("/notifications") ? (
                  <CircleNotificationsIcon
                    style={{ color: "white", fontSize: "1.8rem" }}
                  />
                ) : (
                  <CircleNotificationsIcon
                    style={{ color: "white", fontSize: "1.8rem" }}
                  />
                )}
              </ListItemIcon>
              <ListItemText primary="Notification" />
            </ListItem> */}
            {/* <ListItem
              button
              className={
                props.location.pathname.includes("/faq")
                  ? classes.selectedList
                  : ""
              }
              onClick={() => handleListClick("/faq")}
            >
              <ListItemIcon>
                {props.location.pathname.includes("/faq") ? (
                  <ContactSupportIcon
                    style={{ color: "white", fontSize: "1.8rem" }}
                  />
                ) : (
                  <ContactSupportIcon
                    style={{ color: "white", fontSize: "1.8rem" }}
                  />
                )}
              </ListItemIcon>
              <ListItemText primary="FAQs" />
            </ListItem> */}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              props.location.pathname.includes("/add")
                ? "#fff"
                : theme.palette.grey[100],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          {props.children}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default NavSidebar;
