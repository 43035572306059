import { CircularProgress } from '@mui/material'
import React from 'react';
import styles from './CircularLoader.module.css';

const CircularLoader = ({color}) => {
  return (
    <CircularProgress className={color === "white" ? styles.loaderWhite : styles.loaderBlue} size={15}/>
  )
}

export default CircularLoader;