import React, { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import {
  getUserListOfLikedVideoes,
  getUserWatchedVideos,
} from "../../../redux/api";
import { toHoursAndMinutes } from "../../utils/timeFormat";
const UtableRow = ({ index, name, id, gender, page, email, phone }) => {
  const [likedVideoOfUser, setLikedVideos] = useState([]);
  const [modal, setModal] = useState({
    modal1: false,
    modal2: false,
    username: "",
  });
  const [watchedVideoByUser, setWathedVideoByUser] = useState([]);

  console.log(page);

  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td>{name}</td>
        <td>{phone}</td>
        <td>{email}</td>
        <td>{gender}</td>
        {/* <td>{designation}</td>
        <td>{organisation}</td>
        <td>{username}</td> */}
        {/* <td
          onClick={() =>
            likedVideoOfUser?.length &&
            setModal({
              ...modal,
              modal2: true,
              userName: name,
            })
          }
        >
          <a
            className={`${
              likedVideoOfUser?.length !== 0 && `hover-underline cursor-pointer`
            }`}
          >
            {likedVideoOfUser?.length}
          </a>
        </td>
        <td
          onClick={() =>
            watchedVideoByUser?.length &&
            setModal({ ...modal, modal1: true, userName: name })
          }
        >
          <a
            className={`${
              watchedVideoByUser?.length !== 0 &&
              `hover-underline cursor-pointer`
            }`}
          >
            {watchedVideoByUser?.length}
          </a>
        </td> */}
      </tr>
    </>
  );
};

export default UtableRow;
