// import React from "react";
// import { useState } from "react";
// import { useEffect } from "react";
// import { Button } from "react-bootstrap";
// import { fetchOrderStatus, updateOrderStatus } from "../../redux/api";
// import LoadingPage from "../utils/LoadingPage";

// const Status = () => {
//   const [status, setStatus] = useState();
//   const [discount, setDiscount] = useState();
//   const [update, setUpdate] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const fetch = async () => {
//     const res = await fetchOrderStatus();
//     // setTitle(res.data.termsAndCondition.title);
//     setDiscount(res?.data?.offer?.discountPercentage);
//     setStatus(res?.data?.offer?.isActive);
//   };

//   useEffect(() => {
//     fetch();
//   }, []);
//   useEffect(() => {
//     if (update) {
//       fetch();
//       setUpdate(false);
//     }
//   }, [update]);

//   const updateHandler = async () => {
//     setLoading(true);
//     try {
//       const data = await updateOrderStatus({
//         isActive: !status,
//       });
//       setUpdate(true);
//       setLoading(false);
//       alert("Updated Successfully");
//     } catch {
//       alert("Something went wrong");
//       setLoading(false);
//     }
//   };

//   return (
//     <div>
//       {!loading ? (
//         <div className="text-center m-4">
//           <h4>Current Status : {status === true ? "True" : "False"}</h4>
//           <h4>Discount Percentage : {discount}</h4>
//           <div className="mt-4">
//             <h4>Change current status</h4>
//             <Button className="mt-4" onClick={() => updateHandler()}>
//               Update
//             </Button>
//           </div>
//         </div>
//       ) : (
//         <LoadingPage />
//       )}
//     </div>
//   );
// };

// export default Status;

import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import LoadingPage from "../utils/LoadingPage";
import addIcon from "../../images/addIcon.svg";
import searchIcon from "../../images/searchIcon.svg";
import "../../styles/newstyles/property.css";
import AFtable from "../FeaturedVideos/AllFeatured/AFtable";
import {
  fetchDrivers,
  fetchRiderDetails,
  fetchSchmes,
  getAllProperty,
  GetAllVideos,
  GetFeatureVideo,
} from "../../redux/api";
import { Pagination } from "react-bootstrap";

const Status = () => {
  const [allpropertyData, setallpropertyData] = useState([]);
  console.log(allpropertyData);
  const location = useLocation();
  const path = location.pathname;
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchInput, setsearchInput] = useState("");
  // const [videoCount, setVideoCount] = useState(10);
  const [page, setPage] = useState(0);
  const [filterData, setfilterData] = useState([]);
  const pageLimit = 10;

  const tableHeaders = ["ID", "Discount", "Status"];

  const fetchAllSchmes = async () => {
    setLoading(true);
    try {
      const res = await fetchSchmes();
      console.log(res);
      debugger;
      setallpropertyData(res?.data?.offer);
      // setVideoCount(res?.data?.pageLimit);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAllSchmes();
  }, []);
  useEffect(() => {
    if (update) {
      fetchAllSchmes();
      setUpdate(false);
    }
  }, [update]);

  const searchItems = (searchValue) => {
    setsearchInput(searchValue);
    if (searchValue !== "") {
      let filteredData = allpropertyData.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setfilterData(filteredData);
    } else {
      setfilterData(allpropertyData);
    }
  };

  return (
    <div className="property-container">
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <div className="property-firstSection">
            {/* <div className="property-searchDiv">
              <img src={searchIcon} alt="search" className="searchIcon" />
              <input
                type="text"
                placeholder="Enter a Title , Author or Category"
                className="property-searchInput"
                id="searchInput"
                value={searchInput}
                onChange={(e) => searchItems(e.target.value)}
              />
            </div> */}
          </div>
          <div className="property-tableSection">
            {searchInput.length ? (
              <AFtable tableHeaders={tableHeaders} VideoData={filterData} />
            ) : (
              <AFtable
                path={path}
                tableHeaders={tableHeaders}
                VideoData={allpropertyData}
                setUpdate={setUpdate}
                setLoading={setLoading}
              />
            )}
          </div>
          {/* <div className="property-pagination d-flex justify-content-end mb-5">
            <div>
              <Pagination>
                <Pagination.First onClick={() => setPage(0)} />
                <Pagination.Prev
                  disabled={page === 0}
                  onClick={() => setPage((prev) => prev !== 0 && prev - 1)}
                />

                {[...Array(pageLimit)].map((pa, index) => (
                  <Pagination.Item
                    key={index}
                    onClick={() => setPage(index)}
                    active={index === page}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => setPage((prev) => prev + 1)} />
                <Pagination.Last onClick={() => setPage(pageLimit)} />
              </Pagination>
            </div>
          </div> */}
        </>
      )}
    </div>
  );
};
export default Status;
