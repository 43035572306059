import axios from "axios";
import Cookies from "js-cookie";
let token = localStorage.getItem("admin_bearer");
// for live server
console.log("admin_bearer", token);
const API = axios.create({
  baseURL: "https://api.genz.com.co/",
});
const API1 = axios.create({
  baseURL: "https://api.genz.com.co/",
});

const NewAPI = axios.create({
  baseURL: "https://api.genz.com.co/",
  // baseURL: "http://localhost:3000",
});

API1.interceptors.request.use((config) => {
  if (token) {
    config.headers["authorization"] = `Bearer ${token}`;// for Node.js Express back-end
  }
  return config;
});
API.interceptors.request.use((config) => {
  if (token) {
    config.headers["authorization"] = `Bearer ${token}`;// for Node.js Express back-end
  }
  return config;
});
NewAPI.interceptors.request.use((config) => {
  if (token) {
    config.headers["authorization"] = `Bearer ${token}`; // for Node.js Express back-end
  }
  return config;
});

// signin
export const AdminSignIn = (data) => API1.post("/api/admin/login", data);
export const AdminSignUp = (data) => NewAPI.post("/api/admin/signup", data);
export const getAdmins = () => NewAPI.get("/api/admin");

export const GetQuery = () => NewAPI.get("/api/admin/query?queryIndex=0");
export const replyToMail = (data) => NewAPI.post("/api/admin/reply", data);
export const GetUsers = (page) =>
  API1.get(`/api/admin/users?pageNumber=${page}`);
export const fetchDrivers = (page) =>
  API1.get(`/api/admin/drivers?pageNumber=${page}`);
export const fetchDriverDetails = (id) => API1.get(`/api/admin/driver/${id}`);
export const fetchRiderDetails = (page) =>
  API1.get(`/api/admin/rides?pageNumber=${page}`);
export const fetchAllTranscation = (id, page, type) =>
  API1.get(`/api/admin/driver/${id}/wallet?pageNumber=${page}&mode=${type}`);
export const fetchWalletMoney = (id) =>
  API1.get(`/api/admin/driver/${id}/wallet/balance`);
export const addSlider = (body) => API1.post(`/api/admin/slider`, body);
export const updateSlider = (id, body) => API1.patch(`/api/admin/slider/${id}`, body);
export const fetchDashboard = () => API1.get(`/api/admin/dashboard`);
export const fetchsearchDriver = (search) =>
  API1.get(`/api/admin/search/driver?pageNumber=1&search=${search}`);
export const fetchsearchUser = (search) =>
  API1.get(`/api/admin/search/user?pageNumber=1&search=${search}`);
export const addImage = (data) => API1.post(`/api/admin/image`, data);
export const getSliderImageById = (id) =>
  API1.get(`/api/admin/slider/63ee18219a5ab25dfaaf9303`);
export const fetchFindCar = (number) =>
  API1.get(`/api/admin/driverLocation?carNumber=${number}`);
export const allImage = () => API1.get(`/api/admin/images`);
export const deleteImage = (id) => API1.delete(`/api/admin/image/${id}`);
export const fetchAllRequest = () => API1.get(`/api/admin/requests`);
export const fetchRidesDetails = (id) => API1.get(`/api/admin/ride/${id}`);
export const clearWalletMoney = (id) =>
  API1.get(`/api/admin/driver/${id}/wallet/clearWallet`);
export const fetchSchmes = () => API1.get(`/api/admin/offer`);
export const updateOrderStatus = (id, data) =>
  API1.patch(`/api/admin/offer/${id}`, data);
export const fetchTerms = (type) => API1.get(`api/admin/form_by_type/${type}`);
export const fetchUpdateTerms = (id, data) =>
  API1.patch(`/api/admin/form/${id}`, data);
export const updateDriverStatus = (id, action, reason) =>
  API1.patch(`/api/admin/verifyDriver/${id}?action=${action}&reason=${reason}`);

export const DeleteQuery = (id) => NewAPI.delete(`/api/admin/query/${id}`);

export const PostCategory = (data) => NewAPI.post("/api/admin/category", data);
export const PostPlaylist = (data) => NewAPI.post("/api/admin/playlist", data);
export const updateOrder = (data) => NewAPI.put("/api/admin/playlist", data);
export const UpdatePlaylistCategory = (data) =>
  NewAPI.patch("/api/admin/playlist-category", data);
export const updatePlaylistVideo = (data) =>
  NewAPI.patch("/api/admin/playlist-video", data);
export const categoryOrder = (data) => NewAPI.put("/api/admin/category", data);
export const AddToPlaylist = (data) =>
  NewAPI.post("/api/admin/video/playlist", data);
export const DeleteFromPlaylist = (data) =>
  NewAPI.post("/api/admin/video/playlist", data);

export const GetCategory = () => NewAPI.get("/api/admin/category");
export const GetPlaylist = () => NewAPI.get("/api/admin/playlist");
export const GetPlaylistById = (id) =>
  NewAPI.get(`/api/user/video/playlist?playlist=${id}&videoIndex=0`);

export const GetCategoryCount = () => NewAPI.get("/api/admin/videoCount");

export const AddBlog = (data) => NewAPI.post("/api/admin/blog", data);
export const GetAllBlog = () => NewAPI.get("/api/admin/blog?blogIndex=0");
export const getBlogById = (id) => NewAPI.get(`/api/admin/blog/${id}`);
export const DeleteBlog = (id) => NewAPI.delete(`/api/admin/blog/${id}`);
export const EditBlog = (data) => NewAPI.put(`/api/admin/blog`, data);

export const GetAllVideos = (index, limit = 10, playlist = "") =>
  NewAPI.get(
    `/api/admin/video?videoIndex=${index}&sortBy=date&limit=${limit}${playlist}`
  );

export const GetAllVideosOfPlaylist = (playlist, page, limit = 10) =>
  NewAPI.get(
    `/api/admin/video?videoIndex=${page}&playlist=${playlist}&limit=${limit}`
  );
export const playListById = (id, data) =>
  NewAPI.put(`api/admin/playlist/${id}`, data);
export const editCategoryById = (id, data) =>
  NewAPI.put(`api/admin/category/${id}`, data);

export const getSearchedData = (data) => NewAPI.get("api/admin/allSearchTerms");

export const GetAllTop = () => NewAPI.get("/api/stats/completedVideo/top50");
export const GetAllTop10 = () =>
  NewAPI.get("/api/stats/completedVideo/top10videos");
export const getUserWatchedVideos = (id) =>
  NewAPI.get(`/api/stats/completedVideo/user/${id}`);
export const getUserListOfLikedVideoes = (id) =>
  NewAPI.get(`/api/user/video/likedVideos?userId=${id}`);

export const userLikedVideoes = (id) =>
  NewAPI.get(`/api/stats/likesOfVideo/${id}`);
export const postNotification = (data) =>
  NewAPI.post(`/api/admin/notification`, data);

export const GetNotification = () => NewAPI.get(`/api/user/notification`);
export const GetFaqs = () => NewAPI.get(`/api/admin/faqs`);
export const PostFaqs = (data) => NewAPI.post(`/api/admin/faqs`, data);
export const getFaqId = (data) => NewAPI.post(`/api/admin/faqs/single`, data);
export const updateFaq = (data) => NewAPI.patch(`/api/admin/faqs`, data);
export const DeleteFaq = (data) =>
  NewAPI.delete(`/api/admin/faqs`, { data: data });
export const GetNotificationPanel = () =>
  NewAPI.get(`api/user/notificationPanel`);
export const AddNotification = (data) =>
  NewAPI.post(`api/admin/notificationPanel`, data);

export const EditNotificationById = (id, data) =>
  NewAPI.patch(`api/admin/notificationPanel/${id}`, data);
export const DeleteNotificationPanel = (id, data) =>
  NewAPI.delete(`api/admin/notificationPanel/${id}`, data);

export const getAllUserPlaylist = (lng) =>
  NewAPI.get(`api/user/video/playlists?language=${lng}`);

export const getCompletedPlaylist = (lng) =>
  NewAPI.get(`/api/stats/completedPlaylist`);

export const deletePlaylist = (id) => NewAPI.delete(`api/admin/playlist/${id}`);
export const deleteCategory = (id) => NewAPI.delete(`api/admin/category/${id}`);

export const AddVideo = (data) => NewAPI.post("/api/admin/video", data);
export const GetVideoById = (id) => NewAPI.get(`/api/admin/video/${id}`);

export const GetFeatureVideo = (page) =>
  NewAPI.get(`/api/user/video/featured?videoIndex=${page}`);
export const GetFeatureVideoToAdd = (page) =>
  NewAPI.get(`/api/admin/video?videoIndex=${page}&order=-1`);

export const UpdateVideo = (data) => NewAPI.put(`/api/admin/video`, data);
export const DeleteVideo = (id) => NewAPI.delete(`/api/admin/video/${id}`);
export const publishedVideo = (id, data) =>
  NewAPI.patch(`/api/admin/video/${id}`, data);

export const AddFeatured = (data) =>
  NewAPI.post("/api/admin/video/featured", data);

// statss
export const NoOfRegisteredUser = () =>
  NewAPI.get("/api/stats/registeredUsers");
export const categoryCount = (type, category) =>
  NewAPI.get(`api/stats/categoryWiseCount?${type}=${category}`);
export const TotalHour = () => NewAPI.get("/api/stats/totalHoursOfVideo");

export const getAllProperty = () => API.get("/prop/getAllProperty");
export const addProperty = (data) => API.post("/prop/addProperty", data);

export const deleteProperty = (propid) =>
  API.post("/prop/deleteProperty", { id: propid });
export const getPropertyById = (propid) =>
  API.post("/prop/getPropertyById", { id: propid });

export const updateProperty = (data) => API.post("/prop/updateProperty", data);
export const getAllRequirements = () => API.get("/prop/getRequirements");

// UnitDetails
export const addUnitDetail = (data) => API.post("/prop/addUnitDetail", data);
export const getUnitDetailByPropertyId = (propid) =>
  API.post("/prop/getUnitDetail", { id: propid });
export const deleteUnitDetail = (id, bhk, did) =>
  API.post("/prop/deleteUnitDetail", {
    id: id,
    bhk: bhk,
    detailId: did,
  });

// Developer
export const getAllDeveloper = () => API.get("/dev/getAllDeveloper");
export const addDeveloper = (data) => API.post("/dev/addDeveloper", data);

export const deleteDeveloper = (devid) =>
  API.post("/dev/deleteDeveloper", { id: devid });

export const getDeveloperById = (devid) =>
  API.post("/dev/getDeveloperById", { id: devid });
export const updateDeveloper = (data) => API.post("/dev/updateDeveloper", data);

// Blogs

// export const getAllBlogs = () => API.get('/blog/getAllBlog');
// export const addBlog = (data) => API.post('/blog/addBlog', data);
export const deleteBlog = (blogid) =>
  API.post("/blog/deleteBlog", { id: blogid });
// export const getBlogById = (blogid) =>
//   API.post('/blog/getBlogById', { id: blogid });
export const updateBlog = (data) => NewAPI.put("/api/admin/blog", data);

//Career

export const getAllCareer = () => API.get("/cr/getAllCareer");
export const getCareerById = (blogid) =>
  API.post("/cr/getCareerById", { id: blogid });
export const addCareer = (data) => API.post("/cr/addCareer", data);

export const updateCareer = (data) => API.post("/cr/updateCareer", data);
export const deleteCareer = (blogid) =>
  API.post("/cr/deleteCareer", { id: blogid });

//featured Project

export const getAllProject = () => API.get("/proj/getAllProject");
export const getProjectById = (pid) =>
  API.post("/proj/getProjectById", { id: pid });
export const addProject = (data) => API.post("/proj/addProject", data);
export const updateProject = (data) => API.post("/proj/updateProject", data);
export const deleteProject = (projid) =>
  API.post("/proj/deleteProject", { id: projid });

//Loans

export const getAllLoan = () => API.get("/ln/getAllLoan");
export const getLoanById = (lid) => API.post("/ln/getLoanById", { id: lid });
export const addLoan = (data) => API.post("/ln/addLoan", data);
export const updateLoan = (data) => API.post("/ln/updateLoan", data);
export const deleteLoan = (lid) => API.post("/ln/deleteLoan", { id: lid });

//Contacts

export const getAllContacts = () => API.get("/cn/getAllContacts");
export const getContactsById = (lid) =>
  API.post("/cn/getContactsById", { id: lid });
export const addContacts = (data) => API.post("/cn/addContacts", data);
export const updateContacts = (data) => API.post("/cn/updateContacts", data);
export const deleteContacts = (cid) =>
  API.post("/cn/deleteContacts", { id: cid });

//Experts

export const getAllExperts = () => API.get("/cn/getAllExperts");


export const deleteDriver = (id) => API.delete(`/api/admin/driver/${id}`)
