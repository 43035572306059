import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useHistory } from "react-router-dom";

const NtableRow = ({ name, index, phone, query, id, handleDelete, link }) => {
  const history = useHistory();
  return (
    <>
      <tr>
        <td>{id}</td>
        <td style={{ width: "40%" }}>{name}</td>
        <td>{link}</td>
        <td>
          <button
            onClick={() => history.push("/notifications/add/" + id)}
            className="btn btn-outline-success mx-2"
          >
            <EditIcon />
          </button>
        </td>
      </tr>
    </>
  );
};

export default NtableRow;
