import React, { useState, useEffect } from "react";
import "./ImageTableRow.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteImage } from "../../redux/api";

const ImageTableRow = ({
  page,
  item,
  setLoading,

  index,
  path,
  setUpdate,
  id,
}) => {
  const deleteHandler = async (e, id) => {
    e.preventDefault();
    try {
      const res = deleteImage(id);
      setUpdate(true);
      alert("Deleted Successfully");
    } catch (error) {
      alert(error);
    }
  };

  console.log(index);

  return (
    <>
      <tr>
        {(index || index === 0) && <td>{index + 1}</td>}
        <td>
          <div className="imgDiv">
            <img src={item?.link} alt="" />
          </div>
        </td>
        <td>
          <DeleteIcon onClick={(e) => deleteHandler(e, item?._id)} />
        </td>
      </tr>
    </>
  );
};

export default ImageTableRow;
