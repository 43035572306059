import React, { useState, useEffect } from "react";
import PtableRow from "./AVtableRow";
import "../../../styles/newstyles/table.css";
import { DeleteVideo, publishedVideo } from "../../../redux/api";
import { WindowOutlined } from "@mui/icons-material";
import toast, { Toaster } from "react-hot-toast";

const AVtable = ({ VideoData }) => {
  const [AllVideo, setAllVideo] = useState(VideoData);
  console.log(AllVideo);
  const handleDelete = async (id) => {
    try {
      const yes = window.confirm("Do you want delete ?");
      if (yes) {
        const newarr = AllVideo.filter((item) => item._id != id);
        await DeleteVideo(id);
        toast.success("Video Deleted");
        setAllVideo(newarr);
      }
    } catch (error) {
      toast.success("Couldn't Delete the video");
      console.log(error);
    }
  };
  const handlePublished = async (id) => {
    try {
      const yes = window.confirm("Do you want publish this video ?");
      if (yes) {
        const newarr = AllVideo.filter((item) => item._id != id);
        await publishedVideo(id);
        toast.success("Video Published");
        setAllVideo(newarr);
      }
    } catch (error) {
      toast.error("Couldn't Published the video");
      console.log(error);
    }
  };

  return (
    <div className="table-wrapper" id="#scrollBar">
      <Toaster position="top-right" />
      <table className="fl-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Likes</th>
            <th>Date</th>
            <th>Category</th>
            <th>Playlist</th>
            <th>Uploaded by</th>
            <th>Status</th>
            <th style={{ textAlign: "center" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {AllVideo &&
            AllVideo.map((video, index) => {
              return (
                <PtableRow
                  key={index}
                  index={index}
                  id={video._id}
                  likes={video.videoStatId.likes}
                  title={video.title || video.englishInfo.title}
                  description={video.description}
                  uploadBy={video.uploadedBy}
                  date={video.date}
                  handleDelete={handleDelete}
                  category={
                    video.category
                      ? video.category.name
                      : "No Category Assigned"
                  }
                  playlist={
                    video.playlist
                      ? video.playlist.name
                      : "No Playlist Assigned"
                  }
                  published={video?.isPublished}
                  size={AllVideo?.length}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default AVtable;
