import React, { useState } from "react";

import ReactQuill from "react-quill";
// import toast from "react-hot-toast";
import "react-quill/dist/quill.snow.css";

import { useEffect } from "react";
import { fetchTerms, fetchUpdateTerms } from "../../redux/api";
import { Dropdown } from "react-bootstrap";

function AboutEditor() {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ color: ["red", "#785412", "#000000", "#FFFFFF"] }],
      [{ background: ["red", "#785412"] }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font",
  ];
  const [status, setStatus] = useState("user");

  const [code, setCode] = useState();
  const [title, setTitle] = useState("");
  const [id, setId] = useState();
  const [update, setUpdate] = useState({});
  console.log(code);
  const submitData = async () => {
    try {
      const data = await fetchUpdateTerms(id, {
        status: status,

        description: code,
        type: "about",
      });
      alert("Updated Successfully");
    } catch {
      alert("Something went wrong");
    }
  };

  const fetch = async () => {
    const res = await fetchTerms("about");
    // setTitle(res.data.termsAndCondition.title);
    if (status === "user") {
      setId(res?.data?.form[0]?._id);
      setCode(res.data.form[0]?.description);
    } else {
      setId(res?.data?.form[1]?._id);
      setCode(res.data.form[1]?.description);
    }
  };

  useEffect(() => {
    fetch();
  }, [status]);

  return (
    <>
      <div className="property d-flex justify-content-center mt-4">
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {status}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setStatus("user")}>
              User
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setStatus("driver")}>
              Driver
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        {code && (
          <div>
            <ReactQuill
              theme="snow"
              modules={modules}
              formats={formats}
              value={code}
              onChange={(content, delta, source, editor) => {
                setCode(editor.getHTML());
              }}
            />
          </div>
        )}
      </div>
      <div className="property d-flex justify-content-center">
        <button
          className="mb-5 btn btn-danger  mt-4"
          style={{ cursor: "pointer", widthh: "20%" }}
          onClick={() => submitData()}
        >
          Submit
        </button>
      </div>
    </>
  );
}

export default AboutEditor;
