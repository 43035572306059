import React, { useState, useEffect } from "react";
// import "./TransTable.css";
import Geocode from "react-geocode";

const CarTableRow = ({
  page,
  item,
  setLoading,
  setUpdate,
  index,
  from,
  setFrom,
  path,

  id,
}) => {
  console.log(item);
  // Geocode.setApiKey("AIzaSyDu8rmOqFqCIZ3Y6DMRKnWuSDGs6HMHL0k");
  Geocode.setApiKey("AIzaSyDsyg1jRfHQr1fVS7aJ3ut2qbsZVORL-D0");
  console.log(from);
  useEffect(() => {
    if (item?.location?.coordinates) {
      Geocode.fromLatLng(
        item?.location?.coordinates[0],
        item?.location?.coordinates[1]
      ).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setFrom(address);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [item]);

  console.log(index);

  return (
    <>
      <tr>
        <td>
          {item?.carDetails?.carModel}
          {item?.carDetails?.carColour}
        </td>
        <td title={from}>{from}</td>
        <td>{item?.name}</td>
        <td>{item?.phone}</td>
      </tr>
    </>
  );
};

export default CarTableRow;
