import React, { useState, useEffect } from "react";
// import { DeleteQuery } from "../../../redux/api";
// import "../../../styles/newstyles/table.css";
import toast, { Toaster } from "react-hot-toast";
import { DeleteFaq } from "../../redux/api";
import { FaqTable } from "./FaqTable";

export const FaqRow = ({ QueryData }) => {
  const [query, setquery] = useState([]);
  console.log(query);

  const handleDelete = async (id) => {
    try {
      const yes = window.confirm("Do you want delete ?");
      if (yes) {
        const newarr = query.filter((item) => item._id != id);
        await DeleteFaq({
          id: id,
        });
        setquery(newarr);
        toast.success("Faq Deleted");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setquery(QueryData);
  }, [QueryData]);
  return (
    <>
      {QueryData ? (
        <div className="table-wrapper" id="#scrollBar">
          <table className="fl-table">
            <Toaster />
            <thead>
              <tr>
                <th>ID</th>
                <th>Question</th>
                <th>Answer</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {query.map((item, index) => (
                <FaqTable
                  index={index + 1}
                  key={item._id}
                  handleDelete={handleDelete}
                  id={item._id}
                  name={item.englishInfo?.question}
                  link={item.englishInfo?.answer}
                />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <h6 className="text-center">No details available</h6>
      )}
    </>
  );
};
