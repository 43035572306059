import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from "react-router-dom";
import { createBrowserHistory as createHistory } from "history";
import "bootstrap/dist/css/bootstrap.min.css";
import LoginPage from "./new-components/LoginPage";
import NavSidebar from "./new-components/NavSidebar";
import Allvideos from "./new-components/Videos/Allvideos";
import Career from "./new-components/Career/Career";
import Blogs from "./new-components/Blogs/Blogs";
import AddBlogForm from "./new-components/Blogs/AddBlogForm";
import EditBlogForm from "./new-components/Blogs/EditBlogForm";
import AddCareerForm from "./new-components/Career/AddCareerForm";
import EditCareerForm from "./new-components/Career/EditCareerForm";
import TrendingLoans from "./new-components/TrendingLoans/TrendingLoans";
import AddTrendingLoansForm from "./new-components/TrendingLoans/AddTrendingLoansForm";
import EditTrendingLoansForm from "./new-components/TrendingLoans/EditTrendingLoansForm";
import AddVideoForm from "./new-components/Videos/AddVideoForm";
import EditVideoForm from "./new-components/Videos/EditVideoForm";
import Requirements from "./new-components/Requirements/Requirements";
import UnitDetails from "./new-components/Query/Query";
import AddDeveloperForm from "./new-components/Developer/AddDeveloperForm";
import EditDeveloperForm from "./new-components/Developer/EditDeveloperForm";
import Dashboard from "./new-components/Dashboard/Dashboard";
import User from "./new-components/User/User";
import Query from "./new-components/Query/Query";
import Category from "./new-components/Category/Category";
import AddCategory from "./new-components/Category/AddCategory";
import Playlist from "./new-components/Playlist/Playlist";
import AddPlaylist from "./new-components/Playlist/AddPlaylist";
import FeaturedVideos from "./new-components/FeaturedVideos/FeaturedVideos";
import PlaylistVideos from "./new-components/Playlist/PlaylistVideos/PlaylistVideos";
import Signup from "./new-components/utils/Signup";
import ALLADDVideos from "./new-components/Playlist/AddVideo/ALLADDVideos";
import AddFeaturedVideos from "./new-components/FeaturedVideos/AddFeatureVideos/AddFeatureVideos";
import AdminsList from "./new-components/utils/Admins/AdminsList";
import Notification from "./new-components/Notifications/Notification";
import { AddNotification } from "./new-components/Notifications/AddNotification";
import { NotificationMain } from "./new-components/Notifications/NotificationMain";
import { AddNotificationPanel } from "./new-components/Notifications/NotificationPanel/AddNotificationPanel";
import { FaqTable } from "./new-components/Faq/FaqTable";
import { FaqRow } from "./new-components/Faq/FaqRow";
import { Faq } from "./new-components/Faq/Faq";
import { AddFaqForm } from "./new-components/Faq/AddFaqForm";
import DriverDetails from "./new-components/FeaturedVideos/DriverDetails";
import Riders from "./new-components/riders/Riders";
import Forms from "./new-components/forms/Forms";
import Status from "./new-components/status/Status";
import UiChange from "./new-components/uichanging/UiChange";
import Request from "./new-components/requests/Request";
import RequestDetails from "./new-components/requests/RequestDetails";
import FindCar from "./new-components/findcar/FindCar";
export const history = createHistory();


const App = () => {
  const token = localStorage.getItem("admin_bearer");
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/">
          {token ? <Redirect to="/users" /> : <LoginPage />}
        </Route>
        <NavSidebar>
          <Route exact path="/users">
            {token ? <User /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/drivers">
            {token ? <FeaturedVideos /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/findcar">
            {token ? <FindCar /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/requests">
            {token ? <Request /> : <Redirect to="/" />}
          </Route>

          <Route exact path="/requests/:id">
            {token ? <RequestDetails /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/rides">
            {token ? <Riders /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/forms">
            {token ? <Forms /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/offers">
            {token ? <Status /> : <Redirect to="/" />}
          </Route>

          <Route exact path="/uichanging">
            {token ? <UiChange /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/drivers/:id">
            {token ? <DriverDetails /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/featuredvideos/add">
            {token ? <AddFeaturedVideos /> : <Redirect to="/" />}
          </Route>

          <Route exact path="/dashboard">
            {token ? <Dashboard /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/allvideos">
            {token ? <Allvideos /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/allvideos/add">
            {token ? <AddVideoForm /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/allvideos/edit/:id">
            {token ? <AddVideoForm /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/property/adddev/:id">
            {token ? <AddDeveloperForm /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/property/editdev/:id">
            {token ? <EditDeveloperForm /> : <Redirect to="/" />}
          </Route>
        </NavSidebar>
      </Switch>
    </Router>
  );
};

export default App;
