export const AddData = (data) => {
  return {
    type: "ADD_INITIAL_DATA",
    payload: data,
  };
};
export const AddContent = (data) => {
  return {
    type: "ADD_CONTENT",
    payload: data,
  };
};

export const clearData = (data) => {
  return {
    type: "CLEAR_SECTION",
    payload: data,
  };
};
