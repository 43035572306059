import React, { useState } from "react";
import searchIcon from "../../images/searchIcon.svg";
import CarTable from "./CarTable";
import "../../styles/newstyles/property.css";
import { fetchFindCar } from "../../redux/api";

const FindCar = () => {
  const [searchInput, setsearchInput] = useState("");
  const [filterData, setfilterData] = useState();
  const tableHeaders = ["Car ", "Location", "Name", "Phone"];
  const [from, setFrom] = useState();

  const searchItems = async (searchValue) => {
    setsearchInput(searchValue);
    if (searchValue !== "") {
      try {
        const res = await fetchFindCar(searchValue);
        setfilterData(res?.data);
      } catch {
        setfilterData();
        setFrom("");
      }
      // console.log(res?.data?.status);
      // if (res?.data?.status !== "fail") {
      //   debugger;
      //   setfilterData(res?.data);
      // } else if (res?.data?.status === "fail") {
      //   debugger;
      //   setfilterData();
      // }
    } else {
      setfilterData();
      setFrom("");
    }
  };

  return (
    <div className="property-container">
      <div className="property-firstSection">
        <div className="property-searchDiv">
          <img src={searchIcon} alt="search" className="searchIcon" />
          <input
            type="text"
            placeholder="Search by Car Number"
            className="property-searchInput"
            id="searchInput"
            value={searchInput}
            onChange={(e) => searchItems(e.target.value)}
          />
        </div>
      </div>
      <div>
        {filterData ? (
          <CarTable
            setFrom={setFrom}
            from={from}
            tableHeaders={tableHeaders}
            VideoData={filterData}
          />
        ) : (
          <div className="m-4">
            <h3>No Matches Found</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default FindCar;
