import React, { useState } from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { io } from "socket.io-client";

import {
  clearWalletMoney,
  fetchAllTranscation,
  fetchDriverDetails,
  fetchDrivers,
  fetchRidesDetails,
  fetchWalletMoney,
  updateDriverStatus,
} from "../../redux/api";
import "../../styles/newstyles/property.css";
import CircularLoader from "../../ui/CircularLoader/CircularLoader";
import ModalView from "../../ui/modalview/ModalView";
import LoadingPage from "../utils/LoadingPage";
// import AFtable from "./AllFeatured/AFtable";
import RequestTable from "./RequestTable";
// import TransTable from "./transtable/TransTable";

const RequestDetails = () => {
  const history = useHistory();
  const socket = io.connect("https://go-ev.onrender.com");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [reason, setReason] = useState("");
  const location = useLocation();
  const path = location.pathname;

  const handleClose = () => setOpen(false);
  const [loadingPage, setIsLoadingPage] = useState(false);
  const [update, setUpdate] = useState(false);
  const [loading2, setIsLoading2] = useState(false);
  const { id } = useParams();
  console.log(id);
  const tableHeaders = ["from", "to", "Reason", "Status"];

  const [allpropertyData, setallpropertyData] = useState([]);

  const approveDriverHandler = async () => {
    setLoading(true);
    try {
      socket.emit("UPDATE_REQUEST", {
        rideId: id,
        status: "approved",
      });
      setLoading(false);
      history.push("/requests");
    } catch (error) {
      setLoading(false);
    }
  };
  const rejectDriverHandler = async (e) => {
    e.preventDefault();
    setIsLoading2(true);
    try {
      socket.emit("UPDATE_REQUEST", {
        rideId: id,
        status: "rejected",
      });
      setIsLoading2(false);
      history.push("/requests");
    } catch (error) {
      setIsLoading2(false);
    }
  };

  const fetchDriversData = async () => {
    setIsLoadingPage(true);
    try {
      const res = await fetchRidesDetails(id);
      console.log(res);
      debugger;
      setallpropertyData(res?.data?.ride);
      // setVideoCount(res?.data?.pageLimit);
      setIsLoadingPage(false);
    } catch (error) {
      console.log(error);
      setIsLoadingPage(false);
    }
  };

  useEffect(() => {
    fetchDriversData();
  }, []);

  console.log(allpropertyData);
  return (
    <div className="property-container">
      <div>
        {allpropertyData?.adminstatus === "pending" ||
        allpropertyData?.adminstatus === null ? (
          <>
            <Button onClick={approveDriverHandler} variant="contained">
              {loading ? <CircularLoader color="white" /> : "Approve"}
            </Button>
            <Button onClick={rejectDriverHandler} variant="outlined">
              {loading2 ? <CircularLoader color="blue" /> : "Reject"}
            </Button>
          </>
        ) : allpropertyData?.adminstatus === "rejected" ? (
          <h6 style={{ color: "red" }}>Rejected</h6>
        ) : allpropertyData?.adminstatus === "approved" ? (
          <h6 style={{ color: "green" }}>Approved</h6>
        ) : null}
      </div>
      <div className="property-tableSection">
        {loadingPage ? (
          <LoadingPage />
        ) : (
          <RequestTable
            path={path}
            id={id}
            tableHeaders={tableHeaders}
            VideoData={allpropertyData}
          />
        )}
      </div>

      <ModalView open={open} handleClose={handleClose}>
        <form
          onSubmit={rejectDriverHandler}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h4 style={{ textAlign: "center" }}>Reason</h4>
          <textarea
            required
            onChange={(e) => setReason(e.target.value)}
            name="reason"
            id="reason"
            style={{ width: "100%", outline: "none", marginBottom: "30px" }}
            rows="10"
          ></textarea>
          <div>
            <Button type="submit" variant="contained">
              {loading2 ? <CircularLoader color="blue" /> : "Reject"}
            </Button>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </div>
        </form>
      </ModalView>
    </div>
  );
};

export default RequestDetails;
