import React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { ShowActive } from "../../utils";
import Notification from "./Notification";
import Notifications from "./NotificationPanel/Notifications";

export const NotificationMain = () => {
  const location = useLocation();

  const [selected, setSelected] = useState(
    (location.search === "?ticker" && 1) ||
      (location.search === "?notificationPanel" && 2) ||
      0
  );

  const list = [
    {
      id: 1,
      title: "Ticker",
    },
    {
      id: 2,
      title: "Notifications",
    },
  ];

  const getRendered = (state) => {
    const Screen = {
      1: <Notification />,
      2: <Notifications />,
    };
    if (!Screen[state]) return null;
    return Screen[state];
  };

  return (
    <div>
      <div className="tabbutton mt-5 pt-2" id="tabbutton">
        <ul className="d-flex justify-content-evenly">
          {list.map((item) => (
            <ShowActive
              title={item.title}
              className="tabbutton"
              active={selected === item.id}
              setSelected={setSelected}
              id={item.id}
            />
          ))}
        </ul>
      </div>

      <div className="container">{getRendered(selected)}</div>
    </div>
  );
};
