import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { uploadFile, uploadFile1 } from "../../fileupload";
import {
  addImage,
  addSlider,
  allImage,
  getSliderImageById,
} from "../../redux/api";
import CircularLoader from "../../ui/CircularLoader/CircularLoader";
import ModalView from "../../ui/modalview/ModalView";
import ImageTable from "./ImageTable";

import "../../styles/newstyles/property.css";
import LoadingPage from "../utils/LoadingPage";
import SliderTable from "./SliderTable";

const UiChange = () => {
  const tableHeaders = ["ID", "Image", "Action"];
  const tableHeaders1 = ["ID", "Image", "Action"];
  const [open, setOpen] = useState(false);
  const [sliderData, setSliderData] = useState();
  const [open1, setOpen1] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [data, setData] = useState();
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputFields, setInputFields] = useState({
    link: "",
  });
  const [slider, setSlider] = useState({
    link: "",
  });
  const [loading2, setIsLoading2] = useState(false);

  const fetchImageData = async () => {
    setLoading(true);
    try {
      const res = await allImage();
      console.log(res?.data?.image);
      setData(res?.data?.image);
      // setVideoCount(res?.data?.pageLimit);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const sliderImageData = async () => {
    try {
      const res = await getSliderImageById();
      console.log(res?.data?.image?.link);
      setSliderData(res?.data?.image);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchImageData();
    sliderImageData();
  }, []);
  useEffect(() => {
    if (update) {
      fetchImageData();
      sliderImageData();

      setUpdate(false);
    }
  }, [update]);

  console.log(inputFields);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      link: inputFields?.link,
    };
    if (inputFields?.link?.length > 0) {
      try {
        await addImage(body);
        alert("Updated Successfully");
        setInputFields({
          link: "",
        });

        setUpdate(true);
        handleClose();
      } catch (err) {
        alert("Something went wrong");
        console.log(err);
        // setspinn(false);
      }
    } else {
      alert("Link must not be empty");
    }
  };
  const handleSubmit1 = async (e) => {
    e.preventDefault();
    const body = {
      link: slider?.link,
    };
    if (slider?.link?.length > 0) {
      try {
        await addSlider(body);
        alert("Updated Successfully");
        setSlider("");

        setUpdate(true);
        handleClose1();
      } catch (err) {
        alert("Something went wrong");
        console.log(err);
        // setspinn(false);
      }
    } else {
      alert("Link must not be empty");
    }
  };

  return (
    <div className="property-container">
      {loading ? (
        <LoadingPage />
      ) : (
        <div>
          <Button className="m-4" onClick={handleOpen}>
            Add New
          </Button>
          <div className="property-tableSection">
            <h3>Ui Changing</h3>
            <ImageTable
              tableHeaders={tableHeaders}
              VideoData={data}
              setUpdate={setUpdate}
            />
          </div>
          <div className="property-tableSection">
            <Button className="m-4" onClick={handleOpen1}>
              Add New
            </Button>
            <h3>Slider</h3>
            <SliderTable
              tableHeaders={tableHeaders1}
              VideoData={sliderData}
              setUpdate={setUpdate}
            />
          </div>

          <ModalView open={open} handleClose={handleClose}>
            <h4>Add image</h4>

            <Form onSubmit={(e) => handleSubmit(e)}>
              <Form.Control
                type="file"
                placeholder="Search"
                name="link"
                // value={query}
                onChange={(e) => uploadFile(e, setInputFields)}
              />

              <div style={{ height: "100%", width: "100%" }}>
                <img
                  style={{ width: "25rem", height: "25rem" }}
                  src={inputFields?.link}
                />
              </div>
              <Button type="submit">Add</Button>
            </Form>
          </ModalView>
          <ModalView open={open1} handleClose={handleClose1}>
            <h4>Add image</h4>

            <Form onSubmit={(e) => handleSubmit1(e)}>
              <Form.Control
                type="file"
                placeholder="Search"
                name="link"
                // value={query}
                onChange={(e) => uploadFile1(e, setSlider)}
              />

              <div style={{ height: "100%", width: "100%" }}>
                <img
                  style={{ width: "25rem", height: "25rem" }}
                  src={slider?.link}
                />
              </div>
              <Button type="submit">Add</Button>
            </Form>
          </ModalView>
        </div>
      )}
    </div>
  );
};

export default UiChange;
