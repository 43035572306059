import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import LoadingPage from "../utils/LoadingPage";
import addIcon from "../../images/addIcon.svg";
import searchIcon from "../../images/searchIcon.svg";
import "../../styles/newstyles/property.css";
import AFtable from "./AllFeatured/AFtable";
import {
  fetchDrivers,
  fetchsearchDriver,
  getAllProperty,
  GetAllVideos,
  GetFeatureVideo,
} from "../../redux/api";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const FeaturedVideos = () => {
  const [allpropertyData, setallpropertyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const path = location.pathname;
  const [searchInput, setsearchInput] = useState("");
  // const [videoCount, setVideoCount] = useState(10);
  const [page, setPage] = useState(1);
  const [filterData, setfilterData] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const pageLimit = 10;

  const tableHeaders = ["ID", "Name", "Phone", "Email", "Gender", "Status", "Online", "Action"];

  const fetchDriversData = async () => {
    setLoading(true);
    try {
      const res = await fetchDrivers(page);
      console.log(res);
      setallpropertyData(res?.data?.allDrivers);
           setTotalPage(res?.data?.pages);
      setPage(res?.data?.page)
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDriversData();
  }, [page]);

  const searchItems = async (searchValue) => {
    setsearchInput(searchValue);
    if (searchValue !== "") {
      const res = await fetchsearchDriver(searchValue);
           setTotalPage(res?.data?.pages);
      setPage(res?.data?.page)
      setfilterData(res?.data?.result);
    } else {
      setfilterData(allpropertyData);
    }
  };

        const pageChangeHandler = (e, value) => {
        setPage(value);
      }

  return (
    <div className="property-container">
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <div className="property-firstSection">
            <div className="property-searchDiv">
              <img src={searchIcon} alt="search" className="searchIcon" />
              <input
                type="text"
                placeholder="Search by Phone, Email and Name"
                className="property-searchInput"
                id="searchInput"
                value={searchInput}
                onChange={(e) => searchItems(e.target.value)}
              />
            </div>
            {/* <div className="property-addpropertyDiv">
              <button
                className="property-addBtn"
                onClick={() => history.push("/featuredvideos/add")}
              >
                <img src={addIcon} alt="add" className="property-addIcon" />
                <span>Add Video</span>
              </button>
            </div> */}
          </div>
          <div className="property-tableSection">
            {searchInput.length ? (
              <AFtable
                page={page}
                path={path}
                tableHeaders={tableHeaders}
                VideoData={filterData}
                fetchDriversData={fetchDriversData}
              />
            ) : (
              <AFtable
                page={page}
                path={path}
                tableHeaders={tableHeaders}
                VideoData={allpropertyData}
                fetchDriversData={fetchDriversData}
              />
            )}
          </div>
          <div className="property-pagination d-flex justify-content-end mb-5">
            <div>
<Pagination count={totalPage} page={page} onChange={pageChangeHandler}/>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default FeaturedVideos;
