import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  AddFeatured,
  deleteProperty,
  updateOrderStatus,
  deleteDriver
} from "../../../redux/api";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { SortableHandle } from "react-sortable-hoc";
import Geocode from "react-geocode";
import { Button } from "react-bootstrap";
import axios from "axios";

const AFtableRow = ({
  page,
  item,
  setLoading,
  setUpdate,
  index,
  path,
  name,
  email,
  gender,
  phone,
  status,
  id,
}) => {
  const history = useHistory();
  const [to, setTo] = useState();

  const [from, setFrom] = useState();
  console.log(path, id);

  Geocode.setApiKey("AIzaSyDsyg1jRfHQr1fVS7aJ3ut2qbsZVORL-D0");
  // Geocode.setApiKey("AIzaSyDu8rmOqFqCIZ3Y6DMRKnWuSDGs6HMHL0k");



  useEffect(() => {
    if (item?.fromLocation?.coordinates) {
      Geocode.fromLatLng(
        item?.fromLocation?.coordinates[0],
        item?.fromLocation?.coordinates[1]
      ).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setFrom(address);
        },
        (error) => {
          console.error(error);
        }
      );
    }
    if (item?.toLocation?.coordinates) {
      Geocode.fromLatLng(
        item?.toLocation?.coordinates[0],
        item?.toLocation?.coordinates[1]
      ).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setTo(address);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, []);

  console.log(index);
  const openDetailHandler = () => {
    if (path === "/drivers" || path === "/dashboard") {
      history.push(`/drivers/${id}`);
    }
  };

  const updateHandler = async (id, isActive) => {
    setLoading(true);
    try {
      const data = await updateOrderStatus(id, {
        isActive: !isActive,
      });
      setUpdate(true);
      setLoading(false);
      alert("Updated Successfully");
    } catch {
      alert("Something went wrong");
      setLoading(false);
    }
  };

  const handleDeleteItem = async(id) => {
    const value = window.confirm("Are you sure want to delete?");
    if(value) {
      try {
        const res = await deleteDriver(id);

        
      } catch {
  
      }
              window.location.reload();
    }
  }

  console.log(item, "hit hrehr")

  return (
    <>
      {path === "/rides" && (
        <tr>
          <td>{index + 1}</td>
          <td title={from}>{from}</td>
          <td title={to}>{to}</td>
          {/* <td title={item?.fromLocation?.name}>{item?.fromLocation?.name}</td>
          <td title={item?.toLocation?.name}>{item?.toLocation?.name}</td> */}

          <td>{item?.fare}</td>
          <td>{item?.status}</td>
          {/* <td>{item?.isAvailable ? "Yes" : "No"}</td>
                  <td><Button onClick={() => handleDeleteItem(id)} style={{backgroundColor: "red", color: 'white', border: 'none'}}>Delete</Button></td> */}
        </tr>
      )}

      {path === "/drivers" && (
        <tr>
          <td onClick={openDetailHandler}>{index + 1}</td>
          <td onClick={openDetailHandler}>{item?.name}</td>
          <td onClick={openDetailHandler}>{item?.phone}</td>
          <td onClick={openDetailHandler}>{item?.email}</td>
          <td onClick={openDetailHandler}>{item?.gender}</td>
          <td onClick={openDetailHandler}>{item?.status}</td>
          <td onClick={openDetailHandler}>
            {item?.isAvailable ? "Yes" : "No"}
          </td>
          <td>
            <Button
              onClick={() => handleDeleteItem(id)}
              style={{ backgroundColor: "red", color: "white", border: "none" }}
            >
              Delete
            </Button>
          </td>
        </tr>
      )}
      {path === "/dashboard" && (
        <tr onClick={openDetailHandler}>
          <td>{index + 1}</td>
          <td>{item?.name}</td>
          <td>{item?.phone}</td>
          <td>{item?.email}</td>
          <td>{item?.gender}</td>
          <td>{item?.status}</td>
          {/* <td>{item?.isAvailable ? "Yes" : "No"}</td>
                  <td><Button  onClick={() => handleDeleteItem(id)} style={{backgroundColor: "red", color: 'white', border: 'none'}}>Delete</Button></td> */}
        </tr>
      )}
      {path === `/drivers/${id}` && (
        <tr>
          <td>{index + 1}</td>
          <td>{item?.name}</td>
          <td>{item?.phone}</td>
          <td>{item?.email}</td>
          <td>{item?.gender}</td>
          <td>{item?.status}</td>
          {/* <td>{item?.isAvailable ? "Yes" : "No"}</td>
                  <td><Button  onClick={() => handleDeleteItem(id)} style={{backgroundColor: "red", color: 'white', border: 'none'}}>Delete</Button></td> */}
        </tr>
      )}
      {path === `/offers` && (
        <tr>
          <td>{index + 1}</td>
          <td>{item?.discountPercentage}</td>
          <td>
            {item?.isActive === true ? (
              <Button onClick={() => updateHandler(item?._id, item?.isActive)}>
                Active
              </Button>
            ) : (
              <Button
                onClick={() => updateHandler(item?._id, item?.isActive)}
                variant="danger"
              >
                In Active
              </Button>
            )}
          </td>
          {/* <td><Button  onClick={() => handleDeleteItem(id)} style={{backgroundColor: "red", color: 'white', border: 'none'}}>Delete</Button></td> */}
        </tr>
      )}
    </>
  );
};

export default AFtableRow;
