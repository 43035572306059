import React, { useState, useEffect, useCallback } from "react";
// import "../../../styles/newstyles/table.css";
import "../../styles/newstyles/table.css";
import { useLocation } from "react-router-dom";
import RequestTableRow from "./RequestTableRow";

const RequestTable = ({
  page,
  setUpdate,
  id,
  VideoData,
  tableHeaders,
  path,
}) => {
  console.log(VideoData);
  console.log(path);

  const [video, setvideo] = useState(VideoData);

  return (
    <div className="table-wrapper" id="#scrollBar">
      <table className="fl-table">
        <thead>
          <tr>
            {tableHeaders?.map((item, index) => {
              return <th>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {path === "/requests" &&
            VideoData?.map((item, index) => {
              return (
                <RequestTableRow
                  page={page}
                  item={item}
                  path={path}
                  index={index}
                  setUpdate={setUpdate}
                  // organisation={item.organisation}
                />
              );
            })}
          {
            (path = `/requests/${VideoData?._id}` && (
              <RequestTableRow
                page={page}
                item={VideoData}
                path={path}
                setUpdate={setUpdate}
                // organisation={item.organisation}
              />
            ))
          }
        </tbody>
      </table>
    </div>
  );
};

export default RequestTable;
