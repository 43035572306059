import React, { useState, useEffect, useCallback } from "react";
import "../../../../styles/newstyles/table.css";
import PVtableRow from "./PVtableRow";
import toast, { Toaster } from "react-hot-toast";
import { DeleteFromPlaylist, updatePlaylistVideo } from "../../../../redux/api";
import arrayMove from "../../AllPlaylist/arrayMove";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

const PVtable = ({ QueryData }) => {
  console.log(QueryData, "faksjdkfjaslfkjsalkfjdslkfj");
  const [videos, setvideos] = useState(QueryData);
  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setvideos((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
  });
  const SortableItem = SortableElement((props) => <PVtableRow {...props} />);
  debugger;
  const SortableCont = SortableContainer(({ children }) => {
    return <tbody>{children}</tbody>;
  });
  const handleDelete = async (id) => {
    try {
      const yes = window.confirm("Do you want delete ?");
      if (yes) {
        const temp = {
          videoIds: [`${id}`],
          action: 2,
        };
        const newarr = videos.filter((item) => item._id != id);

        await DeleteFromPlaylist(temp);
        setvideos(newarr);
        toast.success("Video Deleted");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setvideos(QueryData);
  }, [QueryData]);

  useEffect(() => {
    if (videos.length) {
      const updateOrderData = async () => {
        try {
          const newVideo = videos.map((item, index) => {
            item.order = index + 1;
            return item;
          });
          const response = await updatePlaylistVideo({
            orders: newVideo,
          });
        } catch (error) {
          console.log(error);
        }
      };
      updateOrderData();
    }
  }, [videos]);

  return (
    <>
      {QueryData ? (
        <div className="table-wrapper" id="#scrollBar">
          <table className="fl-table">
            <Toaster />
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <SortableCont
              onSortEnd={onSortEnd}
              axis="y"
              lockAxis="y"
              lockToContainerEdges={true}
              lockOffset={["30%", "50%"]}
              helperClass="helperContainerClass"
              useDragHandle={true}
            >
              {videos?.map((item, i) => {
                return (
                  <SortableItem
                    key={i}
                    ids={i}
                    index={i}
                    id={item._id}
                    handleDelete={handleDelete}
                    title={item?.englishInfo?.title || "No Title"}
                  />
                );
              })}
            </SortableCont>
          </table>
        </div>
      ) : (
        <h6 className="text-center">No details available</h6>
      )}
    </>
  );
};

export default PVtable;
