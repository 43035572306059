import produce from "immer";
const initialState = {
  blogData: {},
  tagData: {},
};

export const blogReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case "ADD_INITIAL_BLOG_DATA": {
        draft.blogData = action.payload;
        break;
      }

      case "ADD_CONTENT_IN_BLOG": {
        const olderValue = draft.blogData[action.payload.language] ?? [];
        draft.blogData = {
          ...draft.blogData,
          category: action.payload.data.category,
          readTime: action.payload.data.readTime,
          profile: action.payload.data.profile,
          thumbnail: action.payload.data.thumbnail,
        };
        draft.blogData[action.payload.language] = {
          ...olderValue,
          title: action.payload.data.title,
          author: action.payload.data.author,
          content: action.payload.data.content,
          tags: action.payload.data.tags,
        };
        break;
      }

      case "CLEAR_SECTION_BLOG":
        return {
          ...state,
          blogData: action.payload,
        };
      case "ADD_TAGS": {
        const olderValue = draft.tagData[action.payload.language] ?? [];
        draft.tagData[action.payload.language] = {
          ...olderValue,
          tags: action.payload.data,
        };
        break;
      }
      case "INITIAL_TAGS": {
        draft.tagData = action.payload;
        break;
      }
      default:
        return state;
    }
  });
