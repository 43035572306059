import { Done, HourglassBottom } from "@mui/icons-material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  AddNotification,
  EditNotificationById,
  GetNotification,
  GetNotificationPanel,
  postNotification,
} from "../../../redux/api";

export const AddNotificationPanel = () => {
  const [spinn, setspinn] = useState(false);
  const history = useHistory();
  const params = useParams();
  const [notification, setNotification] = useState([]);
  const [ThumbnailStatus, setThumbnailStatus] = useState(0);
  const [filterdnotification, setFilterdnotification] = useState({});
  const [notificationData, setnotificationData] = useState({
    englishInfo: "",
    hindiInfo: "",
    tamilInfo: "",
    teluguInfo: "",
    marathiInfo: "",
    kannadInfo: "",
    link: "",
    active: null,
    image: "",
  });

  useEffect(() => {
    if (params.id !== undefined) {
      Query();
    }
  }, [params.id]);

  // api call

  const Query = async () => {
    try {
      const data = await GetNotificationPanel();
      setNotification(data?.data?.notification);
    } catch (error) {
      console.log(error);
    }
  };
  const handlesubmit = async (e) => {
    e.preventDefault();
    setspinn(true);
    if (params.id !== undefined) {
      const data = {
        englishInfo:
          notificationData.englishInfo || filterdnotification.englishInfo,
        link: notificationData.link || filterdnotification.link,
        hindiInfo: notificationData.hindiInfo || filterdnotification.hindiInfo,
        tamilInfo: notificationData.tamilInfo || filterdnotification.tamilInfo,
        teluguInfo:
          notificationData.teluguInfo || filterdnotification.teluguInfo,
        marathiInfo:
          notificationData.marathiInfo || filterdnotification.marathiInfo,
        kannadInfo:
          notificationData.kannadInfo || filterdnotification.kannadInfo,
        active: notificationData.active || filterdnotification.active,
        image: notificationData.image || filterdnotification.image,
      };
      try {
        const res = await EditNotificationById(params.id, data);
        setspinn(false);
        history.push("/notifications?notificationPanel");
      } catch (err) {
        console.log(err);
        setspinn(false);
        alert(err.message);
      }
    } else {
      try {
        const data = await AddNotification(notificationData);
        setspinn(false);
        history.push("/notifications?notificationPanel");
      } catch (error) {
        console.log(error);
        setspinn(false);
        alert("Please Fill All Required Information");
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "active") {
      setnotificationData({ ...notificationData, [name]: checked });
    } else {
      setnotificationData({ ...notificationData, [name]: value });
    }
  };
  useEffect(() => {
    if (notification.length) {
      const notificationfiltered = notification.find(
        (item) => item._id === params.id
      );
      setFilterdnotification(notificationfiltered);
    }
  }, [params.id, notification]);

  const handleThumbnailsubmit = async (e) => {
    e.preventDefault();
    setThumbnailStatus(2);
    const formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    try {
      const data = await axios.post(
        "https://aws-uploader.onrender.com/api/v2/samunnati/upload/other",
        formdata
      );
      setnotificationData({ ...notificationData, image: data?.data?.link });
      setThumbnailStatus(1);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <form>
        <div className="addproperty-container mb-5">
          <div className="addproperty-personalDetails">
            {/* 1st row */}
            <div
              className="addproperty-alignRow"
              style={{ flexDirection: "column" }}
            >
              <div className="w-100 form-group">
                <label className="addproperty-inputLabel ">
                  Link
                  <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
                </label>
                <input
                  type="url"
                  name="link"
                  defaultValue={filterdnotification?.link}
                  placeholder="Enter Notification Link (https://www.example.com)"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 form-group mb-2">
                <label className="addproperty-inputLabel ">
                  Notification (English)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>
                    *
                  </span>{" "}
                </label>
                <input
                  type="text"
                  defaultValue={filterdnotification?.englishInfo || ""}
                  name="englishInfo"
                  placeholder="Enter Notification (English)"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 form-group mb-2">
                <label className="addproperty-inputLabel ">
                  Notification (Hindi)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>
                    *
                  </span>{" "}
                </label>
                <input
                  type="text"
                  name="hindiInfo"
                  defaultValue={filterdnotification?.hindiInfo || ""}
                  placeholder="Enter Notification in Hindi"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 form-group mb-2">
                <label className="addproperty-inputLabel ">
                  Notification (Marathi)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>
                    *
                  </span>{" "}
                </label>
                <input
                  type="text"
                  defaultValue={filterdnotification?.marathiInfo || ""}
                  name="marathiInfo"
                  placeholder="Enter Notification in Marathi"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 form-group mb-2">
                <label className="addproperty-inputLabel ">
                  Notification (Telugu)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
                </label>
                <input
                  defaultValue={filterdnotification?.teluguInfo || ""}
                  type="text"
                  name="teluguInfo"
                  placeholder="Enter Notification in Telugu"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 form-group">
                <label className="addproperty-inputLabel ">
                  Notification (Tamil)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
                </label>
                <input
                  type="text"
                  defaultValue={filterdnotification?.tamilInfo || ""}
                  name="tamilInfo"
                  placeholder="Enter Notification in Tamil"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 form-group">
                <label className="addproperty-inputLabel ">
                  Notification (Kannad)
                  <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
                </label>
                <input
                  type="text"
                  defaultValue={filterdnotification?.kannadInfo || ""}
                  name="kannadInfo"
                  placeholder="Enter Notification in Kannad"
                  className="addproperty-inputField w-100"
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 form-group">
                <label className="addproperty-inputLabel ">Image</label>
                <input
                  type="file"
                  defaultValue={filterdnotification?.tamilInfo || ""}
                  name="tamilInfo"
                  placeholder="Enter Notification in Tamil"
                  className="addproperty-inputField w-100"
                  onChange={handleThumbnailsubmit}
                />
                <div className="d-flex flex-column align-items-center">
                  {ThumbnailStatus == 1 ? (
                    <p className="text-success d-flex">
                      Success <Done />
                    </p>
                  ) : ThumbnailStatus == 2 ? (
                    <p className="text-success d-flex">
                      Wait <HourglassBottom />
                    </p>
                  ) : null}
                </div>
                {(filterdnotification?.image || notificationData?.image) && (
                  <img
                    src={filterdnotification?.image || notificationData.image}
                    alt=""
                    className="rounded"
                    height={100}
                    width={100}
                  />
                )}
              </div>
            </div>
            <div className="mt-3">
              <label className="addproperty-inputLabel ">
                Enable Notification
              </label>
              <input
                type="checkbox"
                name="active"
                defaultChecked={
                  notificationData?.active || filterdnotification?.active
                }
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="addproperty-submitDetailDiv">
            <button
              className="addproperty-submitDetailBtn"
              onClick={handlesubmit}
            >
              {params.id ? "Update" : "Submit"}
              {spinn ? (
                <div
                  class="spinner-border spinner-border-sm text-white mx-2"
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                ""
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
