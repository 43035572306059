import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import LoadingPage from "../utils/LoadingPage";
import addIcon from "../../images/addIcon.svg";
import searchIcon from "../../images/searchIcon.svg";
import "../../styles/newstyles/property.css";
import AFtable from "../FeaturedVideos/AllFeatured/AFtable";
import {
  fetchDrivers,
  fetchRiderDetails,
  getAllProperty,
  GetAllVideos,
  GetFeatureVideo,
} from "../../redux/api";
import { Pagination } from "react-bootstrap";

const Riders = () => {
  const [allpropertyData, setallpropertyData] = useState([]);
  console.log(allpropertyData);
  const location = useLocation();
  const path = location.pathname;

  const [loading, setLoading] = useState(false);
  const [searchInput, setsearchInput] = useState("");
  // const [videoCount, setVideoCount] = useState(10);
  const [page, setPage] = useState(0);
  const [filterData, setfilterData] = useState([]);
  const pageLimit = 10;

  const tableHeaders = ["ID", "from", "to", "fare", "Status"];

  const fetchDriversData = async () => {
    setLoading(true);
    try {
      const res = await fetchRiderDetails(page + 1);
      console.log(res);
      setallpropertyData(res?.data?.ride);
      // setVideoCount(res?.data?.pageLimit);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDriversData();
  }, []);
  useEffect(() => {
    fetchDriversData();
  }, [page]);

  const searchItems = (searchValue) => {
    setsearchInput(searchValue);
    if (searchValue !== "") {
      let filteredData = allpropertyData.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setfilterData(filteredData);
    } else {
      setfilterData(allpropertyData);
    }
  };

  return (
    <div className="property-container">
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          {/* <div className="property-firstSection">
            <div className="property-searchDiv">
              <img src={searchIcon} alt="search" className="searchIcon" />
              <input
                type="text"
                placeholder="Enter a Title , Author or Category"
                className="property-searchInput"
                id="searchInput"
                value={searchInput}
                onChange={(e) => searchItems(e.target.value)}
              />
            </div>
          </div> */}
          <div className="property-tableSection">
            {searchInput.length ? (
              <AFtable
                page={page}
                path={path}
                tableHeaders={tableHeaders}
                VideoData={filterData}
              />
            ) : (
              <AFtable
                page={page}
                path={path}
                tableHeaders={tableHeaders}
                VideoData={allpropertyData}
              />
            )}
          </div>
          <div className="property-pagination d-flex justify-content-end mb-5">
            <div>
              <Pagination>
                <Pagination.First onClick={() => setPage(0)} />
                <Pagination.Prev
                  disabled={page === 0}
                  onClick={() => setPage((prev) => prev !== 0 && prev - 1)}
                />

                {[...Array(pageLimit)].map((pa, index) => (
                  <Pagination.Item
                    key={index}
                    onClick={() => setPage(index)}
                    active={index === page}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => setPage((prev) => prev + 1)} />
                <Pagination.Last onClick={() => setPage(pageLimit)} />
              </Pagination>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default Riders;
