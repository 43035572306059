import produce from "immer";
const initialState = {
  videoData: {},
};

export const videoReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case "ADD_INITIAL_DATA": {
        draft.videoData = action.payload;
        break;
      }

      case "ADD_CONTENT": {
        const olderValue = draft.videoData[action.payload.language] ?? [];
        draft.videoData = {
          ...draft.videoData,
          uploadedBy: action.payload.data.uploadedBy,
          duration: action.payload.data.duration,
          date: action.payload.data.date,
          category: action.payload.data.category,
          playlist: action.payload.data.playlist,
          order: action.payload.data.order,
        };
        draft.videoData[action.payload.language] = {
          ...olderValue,
          title: action.payload.data.title,
          thumbnail: action.payload.data.thumbnail,
          url: action.payload.data.url,
          description: action.payload.data.description,
        };
        break;
      }

      case "CLEAR_SECTION":
        return {
          ...state,
          videoData: action.payload,
        };
      default:
        return state;
    }
  });
