import React, { useEffect, useState } from "react";
import "../../styles/newstyles/dashboard.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import {
  categoryCount,
  fetchDashboard,
  fetchDrivers,
  GetAllTop,
  GetAllTop10,
  getAllUserPlaylist,
  GetAllVideos,
  GetCategory,
  getCompletedPlaylist,
  getSearchedData,
  getUserListOfLikedVideoes,
  GetUsers,
  getUserWatchedVideos,
  NoOfRegisteredUser,
  userLikedVideoes,
} from "../../redux/api";
import { Form, Modal, Pagination } from "react-bootstrap";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import CategoryIcon from "@mui/icons-material/Category";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import { GetPlaylistViewLikes, getPlaylistViewLikes } from "./helper";
import { useHistory, useLocation } from "react-router-dom";
import PeopleIcon from "@mui/icons-material/People";
import { SearchTable } from "./SearchTable";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import { toHoursAndMinutes } from "../utils/timeFormat";
import exportFromJSON from "export-from-json";
import AFtable from "../FeaturedVideos/AllFeatured/AFtable";
import Utable from "../User/AllUser/Utable";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
};

const labels = [
  "October",
  "November",
  "December",
  "january",
  "February",
  "March",
];

const data = {
  labels,
  datasets: [
    {
      label: "Agriculture",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Farmer",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

export function Dashboard() {
  const [user, setuser] = useState();
  const [statsData, setStatsData] = useState([]);
  const [lang, setLang] = useState("englishInfo");
  const [video, setVideo] = useState([]);
  const [modalOpened, setModalOpened] = useState(false);
  const [modalOpened1, setModalOpened1] = useState(false);
  const [modalOpened2, setModalOpened2] = useState(false);
  const [modalOpened7, setModalOpened7] = useState(false);
  const [modalOpened3, setModalOpened3] = useState(false);
  const [playlistCompleted, setPlaylistCompleted] = useState([]);
  const [modalOpened4, setModalOpened4] = useState({
    value: false,
    userName: "",
    userId: "",
  });
  const [modalOpened6, setModalOpened6] = useState({
    value: false,
    videoTitle: "",
    videoId: "",
    target: "",
  });

  const location = useLocation();
  const path = location.pathname;
  const [allpropertyData, setallpropertyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [top10, setTop10] = useState([]);
  const [UserData, setUserData] = useState([]);

  const [category, setCategory] = useState("any");
  const [details, setDetails] = useState();
  const history = useHistory();

  const getdata = async () => {
    try {
      const data = await fetchDashboard();
      setDetails(data?.data?.details);
      debugger;

      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDriversData = async () => {
    setLoading(true);
    try {
      const res = await fetchDrivers();
      console.log(res);
      setallpropertyData(res?.data?.allDrivers);
      // setVideoCount(res?.data?.pageLimit);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const getAllUser = async () => {
    try {
      const { data } = await GetUsers();
      setUserData(data?.user);
      console.log(data?.user);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getdata();
    fetchDriversData();
    getAllUser();
  }, []);

  const tableHeaders = ["ID", "Name", "Phone", "Email", "Gender", "Status"];

  console.log(details);
  return (
    <>
      <section className="d-flex flex-column align-items-center">
        <div className="d-flex p-4 justify-content-between align-items-center flex-wrap ">
          <div
            className="box-1 cursor-pointer "
            onClick={() => history.push("/users")}
          >
            <h5>
              Total User
              <PeopleIcon className="mx-2" />
            </h5>
            <p>{details?.userCount || 0}</p>
          </div>
          <div className="box-2" onClick={() => history.push("/rides")}>
            <h5>Total Rides</h5>
            <p>{details?.totalRides || 0}</p>
          </div>
          <div className="box-2" onClick={() => history.push("/rides")}>
            <h5>Completed Rides</h5>
            <p>{details?.completedRides || 0}</p>
          </div>
          <div className="box-2" onClick={() => history.push("/rides")}>
            <h5>Cancel Rides</h5>
            <p>{details?.cancelRides || 0}</p>
          </div>
          <div className="box-2 mt-4" onClick={() => history.push("/drivers")}>
            <h5>Total Drivers</h5>
            <p>{details?.driverCount || 0}</p>
          </div>
        </div>

        {/* <div className="col-8 d-flex jy-content-center ">
          <Bar options={options} data={data} />
        </div> */}
      </section>
      <div className="property-container">
        <h3>Drivers</h3>
        <AFtable
          path={path}
          tableHeaders={tableHeaders}
          VideoData={allpropertyData}
        />
      </div>
      <div className="property-container">
        <h3>Users</h3>
        <Utable page={0} UserData={UserData} />
      </div>
    </>
  );
}

export default Dashboard;
