import React, { useState, useEffect, useCallback } from "react";
import AFtableRow from "./AFtableRow";
import "../../../styles/newstyles/table.css";
import { AddFeatured, updatePlaylistVideo } from "../../../redux/api";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "../../Playlist/AllPlaylist/arrayMove";
import { useLocation } from "react-router-dom";

const AFtable = ({
  page,
  path,
  id,
  VideoData,
  tableHeaders,
  setLoading,
  setUpdate,
  fetchDriversData
}) => {
  console.log(VideoData);
  const location = useLocation();
  const [video, setvideo] = useState(VideoData);

  console.log(path);

  return (
    <div className="table-wrapper" id="#scrollBar">
      <table className="fl-table">
        <thead>
          <tr>
            {tableHeaders?.map((item, index) => {
              return <th>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {path === "/drivers" ||
          path === "/rides" ||
          path === "/offers" ||
          path === "/dashboard" ? (
            VideoData?.map((item, index) => {
              return (
                <AFtableRow
                  page={page}
                  path={path}
                  item={item}
                  name={item.name}
                  index={index}
                  // designation={item.designation}
                  email={item.email}
                  gender={item.gender}
                  phone={item.phone}
                  status={item?.status}
                  // age={item.age}
                  id={item._id}
                  setUpdate={setUpdate}
                  setLoading={setLoading}
                  isAvailable={item?.isAvailable}
                  // organisation={item.organisation}
                />
              );
            })
          ) : (
            <>
              <AFtableRow
                item={VideoData}
                id={id}
                // key={index}
                path={path}
                name={VideoData?.name}
                index={0}
                // designation={item.designation}
                email={VideoData?.email}
                gender={VideoData?.gender}
                phone={VideoData?.phone}
                status={VideoData?.status}
                isAvailable={VideoData?.isAvailable}
                // age={item.age}

                // organisation={item.organisation}
              />
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AFtable;
