import React, { useState, useEffect } from "react";
import "./ImageTableRow.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { addSlider, deleteImage, updateSlider } from "../../redux/api";
import { Button } from "react-bootstrap";

const SliderTableRow = ({
  page,
  item,
  setLoading,

  index,
  path,
  setUpdate,
  id,
}) => {
  console.log(item);

  const deleteHandler = async (e, id) => {
    e.preventDefault();
    try {
      const res = deleteImage(id);
      setUpdate(true);
      alert("Deleted Successfully");
    } catch (error) {
      alert(error);
    }
  };
  const updateHandler = async (status) => {
    try {
      const res = await addSlider({
        link: item?.link,
        status: status,
      });
      alert("Updated Successfully");
      window.location.reload();
    } catch (error) {
      alert(error);
    }
  };

  console.log(item);

  return (
    <>
      <tr>
        <td>1</td>
        <td>
          <div className="imgDiv">
            <img src={item?.link} alt="" />
          </div>
        </td>
        <td>
          {item?.status === true ? (
            <Button onClick={() => updateHandler(false)}>
              Active
            </Button>
          ) : (
            <Button
              onClick={() => updateHandler(true)}
              variant="danger"
            >
              In Active
            </Button>
          )}
        </td>
      </tr>
    </>
  );
};

export default SliderTableRow;
