import React, { useState, useEffect } from "react";
// import "./ImageTableRow.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteImage } from "../../redux/api";
import Geocode from "react-geocode";
import { useHistory } from "react-router-dom";

const RequestTableRow = ({
  page,
  item,
  setLoading,
  path,

  index,
  setUpdate,
  id,
}) => {
  console.log(item);
  const history = useHistory();
  const [to, setTo] = useState();
  const [from, setFrom] = useState();

  // Geocode.setApiKey("AIzaSyDu8rmOqFqCIZ3Y6DMRKnWuSDGs6HMHL0k");
  Geocode.setApiKey("AIzaSyDsyg1jRfHQr1fVS7aJ3ut2qbsZVORL-D0");

  const openDetailHandler = () => {
    if (path === "/requests") {
      history.push(`/requests/${item?._id}`);
    }
  };

  useEffect(() => {
    if (item?.fromLocation?.coordinates) {
      Geocode.fromLatLng(
        item?.fromLocation?.coordinates[0],
        item?.fromLocation?.coordinates[1]
      ).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setFrom(address);
        },
        (error) => {
          console.error(error);
        }
      );
    }
    if (item?.toLocation?.coordinates) {
      Geocode.fromLatLng(
        item?.toLocation?.coordinates[0],
        item?.toLocation?.coordinates[1]
      ).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setTo(address);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, []);

  const deleteHandler = async (e, id) => {
    e.preventDefault();
    try {
      const res = deleteImage(id);
      setUpdate(true);
      alert("Deleted Successfully");
    } catch (error) {
      alert(error);
    }
  };

  console.log(index);

  return (
    <>
      {path === "/requests" && (
        <tr onClick={() => openDetailHandler()}>
          {(index || index === 0) && <td>{index + 1}</td>}
          <td title={from}>{from}</td>
          <td title={to}>{to}</td>
          <td>{item?.reason}</td>
          <td>{item?.driver?.name}</td>
          <td>{item?.driver?.phone}</td>
          <td>{item?.driver?.carDetails?.carNumber}</td>
          <td>{item?.adminstatus}</td>
        </tr>
      )}
      {path === `/requests/${item?._id}` && (
        <tr onClick={() => openDetailHandler()}>
          {(index || index === 0) && <td>{index + 1}</td>}
          <td title={from}>{from}</td>
          <td title={to}>{to}</td>

          <td>{item?.reason}</td>
          <td>{item?.adminstatus}</td>
        </tr>
      )}
    </>
  );
};

export default RequestTableRow;
