import React from "react";
import UtableRow from "./UtableRow";
import "../../../styles/newstyles/table.css";

const Utable = ({ UserData, page }) => {
  return (
    <div className="table-wrapper" id="#scrollBar">
      <table className="fl-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Gender</th>
            {/* <th>Organisation</th>
            <th>Designation</th>
            <th>Username</th>
            <th>Liked</th>
            <th>Watched</th> */}
          </tr>
        </thead>
        <tbody>
          {UserData &&
            UserData.map((item, index) => {
              return (
                <UtableRow
                  key={index}
                  name={item.name}
                  index={index}
                  page={page}
                  // designation={item.designation}
                  email={item.email}
                  gender={item.gender}
                  phone={item.phone}
                  // age={item.age}
                  // id={item._id}
                  // organisation={item.organisation}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default Utable;
