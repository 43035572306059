import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteProperty, DeleteVideo } from "../../../redux/api";
import DeleteModal from "../../utils/DeleteModal";
const AVtableRow = ({
  index,
  title,
  uploadBy,
  likes,
  id,
  description,
  date,
  category,
  size,
  playlist,
  handleDelete,
  published,
}) => {
  return (
    <>
      <tr>
        <td>{size - index}</td>
        <td>{title}</td>
        <td>{likes}</td>
        <td>{date}</td>
        <td>{category}</td>
        <td>{playlist}</td>
        <td>{uploadBy}</td>
        <td className="text-right">
          <div
            className="actions"
            style={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <button className={`${published ? "edit-btn" : "delete-btn"}`}>
              {published ? "Published" : "Unpublished"}{" "}
            </button>
          </div>
        </td>
        <td className="text-right">
          <div
            className="actions"
            style={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <Link to={`/allvideos/edit/${id}`}>
              {" "}
              <button className="edit-btn">
                <ModeEditIcon />{" "}
              </button>
            </Link>
            <button onClick={() => handleDelete(id)} className="delete-btn">
              <DeleteIcon />{" "}
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default AVtableRow;
