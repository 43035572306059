export const ShowActive = ({
  id,
  title,
  icon,
  active,
  setSelected,
  image,
  className,
}) => {
  return (
    <li
      style={{ width: "200px" }}
      className={active ? className + " " + "active" : className}
      onClick={() => setSelected(id)}
    >
      <div className="d-flex justify-content-between">
        <div>
          <p>{title}</p>
        </div>
      </div>
    </li>
  );
};
