import { Done, HourglassBottom } from "@mui/icons-material";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { AddContentInBlog } from "../../../blogReducer/blogAction";
import { GetCategory } from "../../../redux/api";
import { TagsInput } from "./TagsInput";
export const TeluguBlog = () => {
  const [profileStatus, setprofileStatus] = useState(0);
  const [ThumbnailStatus, setThumbnailStatus] = useState(0);
  const [categories, setcategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const blog = useSelector((state) => state.blog);
  const blogDetails = blog.blogData;
  const dispatch = useDispatch();
  const { tagData } = useSelector((state) => state.blog);
  const tags = tagData["teluguInfo"]?.tags ?? [];
  const blogInfo = blog.blogData["teluguInfo"];
  const [blogData, setblogData] = useState({
    title: "",
    thumbnail: "",
    author: "",
    profile: "",
    category: "",
    readTime: "",
    content: "",
  });

  useEffect(() => {
    const date = new Date();
    const newdate = date.toISOString().slice(0, 10);
    setblogData({ ...blogData, date: newdate });
  }, []);

  const handleInputContentchange = (value) => {
    setblogData({ ...blogData, content: value });
  };
  const handleInputchange = (name) => (event) => {
    setblogData({ ...blogData, [name]: event.target.value });
  };

  //photos

  const handleProfilechange = async (e) => {
    e.preventDefault();
    setprofileStatus(2);
    const formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    try {
      const data = await axios.post(
        "https://aws-uploader.onrender.com/api/v2/samunnati/upload/file",
        formdata
      );
      setblogData({ ...blogData, profile: data?.data?.link });
      setprofileStatus(1);
    } catch (error) {
      console.log(error);
    }
  };

  const handleThumbnailchange = async (e) => {
    e.preventDefault();
    setThumbnailStatus(2);
    const formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    try {
      const data = await axios.post(
        "https://aws-uploader.onrender.com/api/v2/samunnati/upload/file",
        formdata
      );
      setblogData({ ...blogData, thumbnail: data?.data?.link });
      setThumbnailStatus(1);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const getcategory = async () => {
      try {
        const data = await GetCategory();
        setcategories(data?.data?.category);
      } catch (error) {
        console.log(error);
      }
    };
    getcategory();
  }, []);

  useEffect(() => {
    if (
      blogData.title ||
      blogData.profile ||
      blogData.thumbnail ||
      blogData.category ||
      blogData.content ||
      blogData.readTime ||
      blogData.author ||
      blogData.content ||
      tags.length > 0
    ) {
      dispatch(
        AddContentInBlog({
          language: "teluguInfo",
          data: {
            title: blogData.title || blogInfo?.title,
            profile: blogData.profile || blogDetails?.profile,
            thumbnail: blogData.thumbnail || blogDetails?.thumbnail,
            category: blogData.category || blogDetails?.category,
            readTime: blogData.readTime || blogDetails?.readTime,
            content: blogData.content || blogInfo?.content,
            author: blogData.author || blogInfo?.author,
            tags: tags || blogInfo?.tags,
          },
        })
      );
    }
  }, [blogData, tags]);
  const getCategory = (item) => {
    return categories.find((i) => i._id === item);
  };
  return (
    <div className="addblog-container">
      <div className="addblog-personalDetails">
        {/* 1st row */}

        <div className="addblog-alignRow">
          {/* aUthor Name */}
          <div className="addblog-inputFieldDiv form-group">
            <label className="addblog-inputLabel ">
              Author Name{" "}
              <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
            </label>
            <input
              type="text"
              defaultValue={blogInfo?.author}
              name="Author"
              placeholder="Full Name"
              className="addblog-inputField"
              onChange={handleInputchange("author")}
            />
          </div>
          {/* Title */}
          <div className="addblog-inputFieldDiv form-group">
            <label className="addblog-inputLabel">
              Blog Title{" "}
              <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
            </label>
            <input
              type="text"
              name="Title"
              defaultValue={blogInfo?.title}
              placeholder="Blog Title"
              className="addblog-inputField"
              onChange={handleInputchange("title")}
            />
          </div>
        </div>

        {/* 2nd row */}
        <div className="addblog-alignRow">
          {/* Category */}
          <div
            className="addblog-inputFieldDiv form-group"
            style={{ width: "90%" }}
          >
            <label className="addblog-inputLabel">Category </label>
            <Form.Select
              aria-label="Select Category"
              placeholder="Title Tagling"
              className="addblog-inputField mt-2"
              onChange={handleInputchange("category")}
            >
              <option selected hidden>
                {blogDetails?.category
                  ? getCategory(blogDetails?.category)?.teluguInfo || ""
                  : "Select Category"}
              </option>
              {blogDetails?.category?.teluguInfo && (
                <option selected>{blogDetails?.category?.teluguInfo}</option>
              )}
              {categories?.map((category) => (
                <option value={category._id}>{category.teluguInfo}</option>
              ))}
            </Form.Select>
          </div>
          {/* TimetoRead */}
          <div className="addblog-textFieldDiv">
            <label className="addblog-inputLabel">
              Time To Read (Minutes){" "}
              <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
            </label>
            <input
              name="minutes"
              min="0"
              defaultValue={blogDetails?.readTime}
              onChange={handleInputchange("readTime")}
              className="addblog-inputField mx-2"
              type="number"
            />
          </div>
        </div>
        {/* 3rd row */}
        <div className="addblog-alignRow">
          {/* Category */}
          <div
            className="addblog-inputFieldDiv form-group"
            style={{ width: "90%" }}
          >
            <label className="addblog-inputLabel">Tags</label>
            <TagsInput
              selectedTags={selectedTags}
              tags={tags.length ? tags : blogInfo?.tags}
              lng={"teluguInfo"}
            />
          </div>
          {/* TimetoRead */}
        </div>

        <div className="addproperty-alignRow d-flex align-items-end">
          {/* Amenities */}
          <div className="addproperty-textFieldDiv me-5">
            <label className="addproperty-inputLabel">
              Choose Profile{" "}
              <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
            </label>
            <input
              type="file"
              name="video"
              placeholder="Upload Video"
              className="addproperty-inputField"
              onChange={handleProfilechange}
            />
          </div>
          <div className="d-flex flex-column align-items-center">
            {profileStatus == 1 ? (
              <p className="text-success d-flex">
                Success <Done />
              </p>
            ) : profileStatus == 2 ? (
              <p className="text-success d-flex">
                Wait <HourglassBottom />
              </p>
            ) : null}
          </div>
        </div>
        {blogDetails?.profile && (
          <img
            src={blogDetails?.profile}
            alt="profile"
            height={200}
            width={200}
          />
        )}
        <div className="addproperty-alignRow d-flex align-items-end">
          {/* Amenities */}
          <div className="addproperty-textFieldDiv me-5">
            <label className="addproperty-inputLabel">
              Choose Thumbnail{" "}
              <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
            </label>
            <input
              type="file"
              name="video"
              placeholder="Upload Video"
              className="addproperty-inputField"
              onChange={handleThumbnailchange}
            />
          </div>
          <div className="d-flex flex-column align-items-center">
            {ThumbnailStatus == 1 ? (
              <p className="text-success d-flex">
                Success <Done />
              </p>
            ) : ThumbnailStatus == 2 ? (
              <p className="text-success d-flex">
                Wait <HourglassBottom />
              </p>
            ) : null}
          </div>
        </div>
        {blogDetails?.thumbnail && (
          <img
            src={blogDetails?.thumbnail}
            alt="profile"
            height={200}
            width={200}
          />
        )}

        {/* 5th row */}
        <div className="addblog-alignRow">
          {/*content*/}
          <div className="addblog-textFieldDiv">
            <label className="addblog-inputLabel">
              Content{" "}
              <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>{" "}
            </label>
            <ReactQuill
              className="addblog-textField"
              placeholder="Add Blog Content here"
              modules={TeluguBlog.modules}
              formats={TeluguBlog.formats}
              defaultValue={blogInfo.content}
              theme="snow"
              onChange={(content, delta, source, editor) => {
                setblogData({ ...blogData, content: editor.getHTML() });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
TeluguBlog.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];
TeluguBlog.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
