import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  deleteUnitDetail,
  GetAllVideosOfPlaylist,
  UpdatePlaylistCategory,
} from "../../../redux/api";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { SortableHandle } from "react-sortable-hoc";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { toast } from "react-hot-toast";

const PtableRow = ({
  name,
  index,
  phone,
  id,
  handleDelete,
  category,
  totalVideos,
  className,
  orderUpdate,
  categoryName,
}) => {
  const history = useHistory();
  const updateCategory = async (e) => {
    try {
      const yes = window.confirm("Do you want to update the category ?");
      if (!yes) {
        return;
      } else {
        const data = await UpdatePlaylistCategory({
          id: id,
          categoryId: e.target.value,
        });
        console.log(data);
        toast.success("Category Updated");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const RowHandler = SortableHandle((e) => (
    <a onMouseUp={() => orderUpdate(id)}>
      <DragIndicatorIcon />
    </a>
  ));

  return (
    <tr>
      <td className="handle" style={{ cursor: "grab" }} data-id={id}>
        {/* <div className="d-flex"> */}
        <RowHandler />
        {id.slice(3, 7)}
        {/* </div> */}
      </td>
      <td>{name}</td>
      <td>
        <button
          onClick={() => history.push(`/playlist/videos/${name}/${id}`)}
          className="btn-outline-primary-admin "
        >
          View Videos
        </button>
      </td>
      <td>
        <button
          onClick={() => history.push(`/playlist/addvideos/${name}/${id}`)}
          className="btn-outline-primary-admin "
        >
          Add Videos
        </button>
      </td>
      <td>{totalVideos}</td>
      <td>
        <select
          onChange={(e) => updateCategory(e)}
          style={{
            overflow: "hidden",
            width: "100px",
            height: "35px",
            borderRadius: "8px",
          }}
        >
          <option value="" hidden selected>
            {categoryName ? categoryName : "Select Category"}
          </option>
          {category.map((item) => (
            <option value={item._id}>{item.name}</option>
          ))}
        </select>
      </td>
      <td className="text-right">
        <div
          className="actions"
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <Link to={`/playlist/edit/${id}`}>
            <button className="edit-btn">
              <ModeEditIcon />{" "}
            </button>
          </Link>
          <button onClick={() => handleDelete(id)} className="delete-btn">
            <DeleteIcon />{" "}
          </button>
        </div>
      </td>
    </tr>
  );
};

export default PtableRow;
