import React, { useState, useEffect } from "react";
import "./TransTable.css";

const TransTableRow = ({
  page,
  item,
  setLoading,
  setUpdate,
  index,
  path,

  id,
}) => {
  console.log(item, "fdjsfjdshfjdsfjdshfhdsjfh");

  console.log(index);

  return (
    <>
      <tr>
        {(index || index === 0) && <td>{index + 1}</td>}
        <td
          className={`${
            item?.action === "debit" ? "amountColorRed" : "amountColorGreen"
          }`}
        >
          {item?.action}
        </td>
        <td>{item?.description}</td>
        <td>{item?.createdAt}</td>
        <td> {Number(item?.amount).toFixed(2)}</td>
        <td> {item?.paymentMode}</td>
      </tr>
    </>
  );
};

export default TransTableRow;
