import React, { useState, useEffect, useCallback } from "react";
import "../../../styles/newstyles/table.css";

import { useLocation } from "react-router-dom";
import TransTableRow from "./TransTableRow";

const TransTable = ({
  page,

  id,
  VideoData,
  tableHeaders,
}) => {
  console.log(VideoData);
  const location = useLocation();
  const [video, setvideo] = useState(VideoData);

  return (
    <div className="table-wrapper" id="#scrollBar">
      <table className="fl-table">
        <thead>
          <tr>
            {tableHeaders?.map((item, index) => {
              return <th>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {VideoData?.map((item, index) => {
            return (
              <TransTableRow
                page={page}
                item={item}
                index={index}

                // organisation={item.organisation}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TransTable;
