import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import LoadingPage from "../utils/LoadingPage";
import addIcon from "../../images/addIcon.svg";
import searchIcon from "../../images/searchIcon.svg";
import "../../styles/newstyles/property.css";
import Ptable from "./AllVideos/AVtable";
import { getAllProperty, GetAllVideos } from "../../redux/api";
import { AddData } from "../../videoReducer/videoAction";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "react-bootstrap";

const Allvideos = () => {
  const history = useHistory();
  const [allpropertyData, setallpropertyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setsearchInput] = useState("");
  const [filterData, setfilterData] = useState([]);
  const [videoCount, setVideoCount] = useState(0);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  console.log(allpropertyData);
  const fetchpropertyList = async () => {
    setLoading(true);
    try {
      const res = await GetAllVideos(page);
      console.log(res);
      setallpropertyData(res?.data?.data);
      setVideoCount(res?.data?.pageLimit);

      setLoading(false);
    } catch (error) {
      console.warn(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchpropertyList();
  }, [page]);

  const pageLimit = Math.ceil(videoCount / 10);

  const searchItems = (searchValue) => {
    setsearchInput(searchValue);
    if (searchValue !== "") {
      let filteredData = allpropertyData.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setfilterData(filteredData);
    } else {
      setfilterData(allpropertyData);
    }
  };

  const { videoData } = useSelector((state) => state.video);
  console.log(videoData);

  const addVideoData = () => {
    const initialData = {
      uploadedBy: "",
      duration: 0,
      category: "",
      playlist: "",
      date: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
      order: 0,
      englishInfo: {
        title: "",
        url: "",
        description: "",
        thumbnail: "",
        category: "",
      },
      hindiInfo: {
        title: "",
        url: "",
        description: "",
        thumbnail: "",
        category: "",
      },
      tamilInfo: {
        title: "",
        url: "",
        description: "",
        thumbnail: "",
        category: "",
      },
      teluguInfo: {
        title: "",
        url: "",
        description: "",
        category: "",
        thumbnail: "",
      },
      marathiInfo: {
        title: "",
        url: "",
        description: "",
        thumbnail: "",
        category: "",
      },
      kannadInfo: {
        title: "",
        url: "",
        description: "",
        thumbnail: "",
        category: "",
      },
    };
    dispatch(AddData(initialData));
    history.push("/allvideos/add");
  };

  return (
    <div className="property-container">
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <div className="property-firstSection">
            <div className="property-searchDiv">
              <img src={searchIcon} alt="search" className="searchIcon" />
              <input
                type="text"
                placeholder="Enter a Title , Author or Category"
                className="property-searchInput"
                id="searchInput"
                value={searchInput}
                onChange={(e) => searchItems(e.target.value)}
              />
            </div>
            <div className="property-addpropertyDiv">
              <button className="property-addBtn" onClick={addVideoData}>
                <img src={addIcon} alt="add" className="property-addIcon" />
                <span>Add Video</span>
              </button>
            </div>
          </div>
          <div className="property-tableSection">
            {searchInput.length ? (
              <Ptable VideoData={filterData} />
            ) : (
              allpropertyData?.length && <Ptable VideoData={allpropertyData} />
            )}
          </div>
          <>
            <div className="property-pagination d-flex justify-content-end mb-5">
              <div>
                <Pagination>
                  <Pagination.First onClick={() => setPage(0)} />
                  <Pagination.Prev
                    disabled={page === 0}
                    onClick={() => setPage((prev) => prev !== 0 && prev - 1)}
                  />

                  {[...Array(pageLimit)].map((pa, index) => (
                    <Pagination.Item
                      key={index}
                      onClick={() => setPage(index)}
                      active={index === page}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() => setPage((prev) => prev + 1)}
                  />
                  <Pagination.Last onClick={() => setPage(pageLimit)} />
                </Pagination>
              </div>
            </div>
          </>
        </>
      )}
    </div>
  );
};
export default Allvideos;
