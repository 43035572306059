import React, { useState, useEffect, useCallback } from "react";
// import "../../../styles/newstyles/table.css";
import "../../styles/newstyles/table.css";
import { useLocation } from "react-router-dom";
import SliderTableRow from "./SliderTableRow";

const SliderTable = ({ page, setUpdate, id, VideoData, tableHeaders }) => {
  console.log(VideoData);
  const location = useLocation();
  const [video, setvideo] = useState(VideoData);

  return (
    <div className="table-wrapper" id="#scrollBar">
      <table className="fl-table">
        <thead>
          <tr>
            {tableHeaders?.map((item, index) => {
              return <th>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          <SliderTableRow
            page={page}
            item={VideoData}
            setUpdate={setUpdate}
            // organisation={item.organisation}
          />
        </tbody>
      </table>
    </div>
  );
};

export default SliderTable;
